<template>
  <div class="page">
    <div class="setting-title">系统设置</div>
    <ul class="tab">
      <li
        @click="switchLi(item)"
        :class="selectedLi == item ? 'selectedLi' : ''"
        v-for="item in tabList"
        :key="item"
      >
        {{ item }}
      </li>
    </ul>
    <div class="tab-content" v-if="selectedLi == '基本信息'">
      <el-card shadow="never" class="base-info">
        <Title>基本信息</Title>
        <ul class="info-list">
          <li class="texthidden">
            机构名称：
            <span>{{ essentialInformation.name || "无" }}</span>
          </li>
          <li class="texthidden">
            构机简称：
            <span>{{ essentialInformation.shortName || "无" }}</span>
          </li>
          <li class="texthidden"></li>
          <li class="texthidden">
            所属区域：
            <span>{{ essentialInformation.orgRegion || "无" }}</span>
          </li>
          <li class="texthidden">
            机构类型：
            <span>{{ essentialInformation.typeName || "无" }}</span>
          </li>
          <li class="texthidden">
            上级机构：
            <span>{{ essentialInformation.parentName || "无" }}</span>
          </li>
          <li class="texthidden">
            机构地址：
            <span>{{ essentialInformation.address || "无" }}</span>
          </li>
          <li class="texthidden">
            销售人员：
            <span>{{ essentialInformation.salesName || "无" }}</span>
          </li>
          <li class="texthidden">
            销售电话：
            <span>{{ essentialInformation.salesTel || "无" }}</span>
          </li>
        </ul>
        <img src="@/assets/img/zhang/setting.png" alt="" />
      </el-card>
      <el-card shadow="never" class="administrator">
        <Title>管理员</Title>
        <div class="admin-list">
          <el-table
            :data="tableData"
            stripe
            :header-cell-style="{
              'text-align': 'center',
              background: '#F6F7FA',
              color: '#333',
            }"
            :cell-style="{ 'text-align': 'center' }"
            style="
              width: 100%;
              text-align: center;
              border: 1px solid #e4e6ec;
              border-bottom: none;
            "
            v-loading="administratorLoading"
          >
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column
              prop="wechatName"
              label="用户名(微信昵称)"
            ></el-table-column>
            <el-table-column prop="phoneNum" label="手机号"></el-table-column>
            <el-table-column
              prop="bindingTime"
              label="绑定时间"
            ></el-table-column>
            <el-table-column
              prop="bindingTime"
              label="最后登陆时间/IP时间"
            ></el-table-column>
          </el-table>
        </div>
        <Pagination
          :pageNumber="page.pageNumber"
          :pageSize="page.pageSize"
          :total="total"
          @SizeChange="SizeChange"
          @CurrentChange="CurrentChange"
        ></Pagination>
      </el-card>
    </div>
    <div class="tab-content" v-if="selectedLi == '预警规则'" v-loading="warnLoading">
      <el-card shadow="never" class="warning-info">
        <div class="titleWarning">
          <div class="titleWar">
            <Title>预警规则</Title>
            <div
              class="bianji"
              @click="editWarningSetting"
              v-if="!warningSettingControl"
            >
              <img src="@/assets/img/lvchaofeng/set/bianjiTP.png" alt="" />
              <span>编辑</span>
            </div>
          </div>
          <div class="button" v-if="warningSettingControl">
            <div class="search" @click="cancelWarningSeting">取消</div>
            <div class="reset" @click="saveAlertRules">保存</div>
          </div>
        </div>
        <!-- 显示 -->
        <div v-if="!warningSettingControl" class="displayStyle">
          <div class="warning-radio">
            <div class="err-radio">
              <div class="title" style="font-weight: 600">
                <span>*</span>
                错误表述预警
              </div>
              <span class="displayStyleSpan">
                {{
                  misrepresentationInversion.isWarning == 1 ? "预警" : "不预警"
                }}
              </span>
            </div>
          </div>
          <div class="warning-form">
            <div class="warning-form-date">
              <span style="font-weight: 600">预警时间段</span>
              <span class="displayStyleSpan">
                {{
                  misrepresentationInversion.startTime &&
                  misrepresentationInversion.endTime
                    ? misrepresentationInversion.startTime +
                      " 至 " +
                      misrepresentationInversion.endTime
                    : "未设置"
                }}
              </span>
            </div>
            <div class="warning-form-checkbox">
              <span style="font-weight: 600">预警设置</span>
              <div class="warnSelect">
                <div
                  style="
                    height: 40px;
                    line-height: 40px;
                    box-sizing: border-box;
                    padding-left: 20px;
                    font-weight: 600;
                    border-bottom: 1px solid #dcdfe6;
                  "
                >
                  所选事件 ({{
                    misrepresentationInversion.selectedEventsArr
                      ? misrepresentationInversion.selectedEventsArr.length
                      : 0
                  }})
                </div>
                <div class="warnSelectList">
                  <div
                    v-for="(
                      item, index
                    ) in misrepresentationInversion.selectedEventsArr"
                    :key="index"
                    style="
                      height: 30px;
                      line-height: 30px;
                      box-sizing: border-box;
                      padding-left: 20px;
                      color: #606266;
                    "
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="warning-radio">
            <div class="err-radio">
              <div class="title" style="font-weight: 600">
                <span>*</span>
                有害信息预警
              </div>
              <span class="displayStyleSpan">
                {{
                  harmfulInformationInversion.isWarning == 1 ? "预警" : "不预警"
                }}
              </span>
            </div>
          </div>
          <div class="warning-form">
            <div class="warning-form-date">
              <span style="font-weight: 600">预警时间段</span>
              <span class="displayStyleSpan">
                {{
                  harmfulInformationInversion.startTime &&
                  harmfulInformationInversion.endTime
                    ? harmfulInformationInversion.startTime +
                      " 至 " +
                      harmfulInformationInversion.endTime
                    : "未设置"
                }}
              </span>
            </div>
            <div class="warning-form-checkbox">
              <span style="font-weight: 600">预警设置</span>
              <div class="warnSelect">
                <div
                  style="
                    height: 40px;
                    line-height: 40px;
                    box-sizing: border-box;
                    padding-left: 20px;
                    font-weight: 600;
                    border-bottom: 1px solid #dcdfe6;
                  "
                >
                  所选事件({{
                    harmfulInformationInversion.selectedEventsArr
                      ? harmfulInformationInversion.selectedEventsArr.length
                      : 0
                  }})
                </div>
                <div class="warnSelectList">
                  <div
                    v-for="(
                      item, index
                    ) in harmfulInformationInversion.selectedEventsArr"
                    :key="index"
                    style="
                      height: 30px;
                      line-height: 30px;
                      box-sizing: border-box;
                      padding-left: 20px;
                      color: #606266;
                    "
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="warning-radio">
            <div class="err-radio">
              <div class="title" style="font-weight: 600">
                <span>*</span>
                异常状态预警
              </div>
              <span class="displayStyleSpan">
                {{ abnormalStateInversion.isWarning == 1 ? "预警" : "不预警" }}
              </span>
            </div>
          </div>
          <div class="warning-form">
            <div class="warning-form-date">
              <span style="font-weight: 600">预警时间段</span>
              <span class="displayStyleSpan">
                {{
                  abnormalStateInversion.startTime &&
                  abnormalStateInversion.endTime
                    ? abnormalStateInversion.startTime +
                      " 至 " +
                      abnormalStateInversion.endTime
                    : "未设置"
                }}
              </span>
            </div>
            <div class="warning-form-checkbox">
              <span style="font-weight: 600">预警设置</span>
              <div class="warnSelect">
                <div
                  style="
                    height: 40px;
                    line-height: 40px;
                    box-sizing: border-box;
                    padding-left: 20px;
                    font-weight: 600;
                    border-bottom: 1px solid #dcdfe6;
                  "
                >
                  所选事件({{
                    abnormalStateInversion.selectedEventsArr
                      ? abnormalStateInversion.selectedEventsArr.length
                      : 0
                  }})
                </div>
                <div class="warnSelectList">
                  <div
                    v-for="(
                      item, index
                    ) in abnormalStateInversion.selectedEventsArr"
                    :key="index"
                    style="
                      height: 30px;
                      line-height: 30px;
                      box-sizing: border-box;
                      padding-left: 20px;
                      color: #606266;
                    "
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="warning-radio">
            <div class="err-radio">
              <div class="title" style="font-weight: 600">
                <span>*</span>
                监测方案预警
              </div>
              <span class="displayStyleSpan">
                {{
                  monitoringProgrammeInversion.isWarning == 1
                    ? "预警"
                    : "不预警"
                }}
              </span>
            </div>
          </div>
          <div class="warning-form">
            <div class="warning-form-date">
              <span style="font-weight: 600">预警时间段</span>
              <span class="displayStyleSpan">
                {{
                  monitoringProgrammeInversion.startTime &&
                  monitoringProgrammeInversion.endTime
                    ? monitoringProgrammeInversion.startTime +
                      " 至 " +
                      monitoringProgrammeInversion.endTime
                    : "未设置"
                }}
              </span>
            </div>
            <div class="warning-form-checkbox">
              <span style="font-weight: 600">预警设置</span>
              <div class="warnSelect">
                <div
                  style="
                    height: 40px;
                    line-height: 40px;
                    box-sizing: border-box;
                    padding-left: 20px;
                    font-weight: 600;
                    border-bottom: 1px solid #dcdfe6;
                  "
                >
                  所选事件({{
                    monitoringProgrammeInversion.selectedEventsArr
                      ? monitoringProgrammeInversion.selectedEventsArr.length
                      : 0
                  }})
                </div>
                <div class="warnSelectList">
                  <div
                    v-for="(
                      item, index
                    ) in monitoringProgrammeInversion.selectedEventsArr"
                    :key="index"
                    style="
                      height: 30px;
                      line-height: 30px;
                      box-sizing: border-box;
                      padding-left: 20px;
                      color: #606266;
                    "
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 编辑 -->
        <div v-if="warningSettingControl">
          <!-- 错误表述 -->
          <div class="warning-radio">
            <div class="err-radio">
              <div class="title" style="font-weight: 600">
                <span>*</span>
                错误表述预警
              </div>
              <el-radio
                v-model="misrepresentationInversion.isWarning"
                label="1"
                :disabled="MisrepresentationSelect.length == 0"
              >
                预警
              </el-radio>
              <el-radio v-model="misrepresentationInversion.isWarning" label="0"
                >不预警</el-radio
              >
            </div>
          </div>
          <div class="warning-form">
            <div class="warning-form-date">
              <span style="font-weight: 600">预警时间段</span>
              <el-time-picker
                v-model="misrepresentationInversion.warningTime"
                is-range
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm:ss"
                @input="changeDate"
              ></el-time-picker>
            </div>
            <div class="warning-form-checkbox">
              <span style="font-weight: 600">预警设置</span>
              <div class="warnSelect">
                <div
                  style="
                    border-bottom: 1px solid #dcdfe6;
                    height: 40px;
                    box-sizing: border-box;
                    padding-top: 6px;
                  "
                >
                  <el-checkbox v-model="warnCheckAll" @change="warSetAllChange">
                    全部事件
                  </el-checkbox>
                </div>
                <div class="warnSelectList">
                  <el-checkbox-group
                    v-model="misrepresentationInversion.messageType"
                    @change="warSetChange"
                  >
                    <div>
                      <el-checkbox
                        v-for="(item, index) in MisrepresentationSelect"
                        :label="item.id"
                        :key="index"
                      >
                        {{ item.name }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
          <!-- 有害信息 -->
          <div class="warning-radio">
            <div class="err-radio">
              <div class="title" style="font-weight: 600">
                <span>*</span>
                有害信息预警
              </div>
              <el-radio
                v-model="harmfulInformationInversion.isWarning"
                label="1"
                :disabled="warnSelectList.length == 0"
              >
                预警
              </el-radio>
              <el-radio
                v-model="harmfulInformationInversion.isWarning"
                label="0"
                >不预警</el-radio
              >
            </div>
          </div>
          <div class="warning-form">
            <div class="warning-form-date">
              <span style="font-weight: 600">预警时间段</span>
              <el-time-picker
                v-model="harmfulInformationInversion.warningTime"
                is-range
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm:ss"
                @input="changeDate"
              ></el-time-picker>
            </div>
            <div class="warning-form-checkbox">
              <span style="font-weight: 600">预警设置</span>
              <div class="warnSelect">
                <div
                  style="
                    border-bottom: 1px solid #dcdfe6;
                    height: 40px;
                    box-sizing: border-box;
                    padding-top: 6px;
                  "
                >
                  <el-checkbox
                    v-model="warnCheckAllTwo"
                    @change="warSetAllChangeTwo"
                  >
                    全部事件
                  </el-checkbox>
                </div>

                <div class="warnSelectList">
                  <el-checkbox-group
                    v-model="harmfulInformationInversion.messageType"
                    @change="warSetChangeTwo"
                  >
                    <div>
                      <el-checkbox
                        v-for="(item, index) in warnSelectList"
                        :label="item.value"
                        :key="index"
                      >
                        {{ item.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
          <!-- 异常状态 -->
          <div class="warning-radio">
            <div class="err-radio">
              <div class="title" style="font-weight: 600">
                <span>*</span>
                异常状态预警
              </div>
              <el-radio
                v-model="abnormalStateInversion.isWarning"
                label="1"
                :disabled="warnSelectList.length == 0"
              >
                预警
              </el-radio>
              <el-radio v-model="abnormalStateInversion.isWarning" label="0"
                >不预警</el-radio
              >
            </div>
          </div>
          <div class="warning-form">
            <div class="warning-form-date">
              <span style="font-weight: 600">预警时间段</span>
              <el-time-picker
                v-model="abnormalStateInversion.warningTime"
                is-range
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm:ss"
                @input="changeDate"
              ></el-time-picker>
            </div>
            <div class="warning-form-checkbox">
              <span style="font-weight: 600">预警设置</span>
              <div class="warnSelect">
                <div
                  style="
                    border-bottom: 1px solid #dcdfe6;
                    height: 40px;
                    box-sizing: border-box;
                    padding-top: 6px;
                  "
                >
                  <el-checkbox
                    v-model="warnCheckAllThree"
                    @change="warSetAllChangeThree"
                  >
                    全部事件
                  </el-checkbox>
                </div>

                <div class="warnSelectList">
                  <el-checkbox-group
                    v-model="abnormalStateInversion.messageType"
                    @change="warSetChangeThree"
                  >
                    <div>
                      <el-checkbox
                        v-for="(item, index) in warnSelectList"
                        :label="item.value"
                        :key="index"
                      >
                        {{ item.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
          <!-- 监测方案 -->
          <div class="warning-radio">
            <div class="err-radio">
              <div class="title" style="font-weight: 600">
                <span>*</span>
                监测方案预警
              </div>
              <el-radio
                v-model="monitoringProgrammeInversion.isWarning"
                label="1"
                :disabled="DetectionSchemeSelect.length == 0"
              >
                预警
              </el-radio>
              <el-radio
                v-model="monitoringProgrammeInversion.isWarning"
                label="0"
                >不预警</el-radio
              >
            </div>
          </div>
          <div class="warning-form">
            <div class="warning-form-date">
              <span style="font-weight: 600">预警时间段</span>
              <el-time-picker
                v-model="monitoringProgrammeInversion.warningTime"
                is-range
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm:ss"
                @input="changeDate"
              ></el-time-picker>
            </div>
            <div class="warning-form-checkbox">
              <span style="font-weight: 600">预警设置</span>
              <div class="warnSelect">
                <div
                  style="
                    border-bottom: 1px solid #dcdfe6;
                    height: 40px;
                    box-sizing: border-box;
                    padding-top: 6px;
                  "
                >
                  <el-checkbox
                    v-model="warnCheckAllFour"
                    @change="warSetAllChangeFour"
                  >
                    全部事件
                  </el-checkbox>
                </div>

                <div class="warnSelectList">
                  <el-checkbox-group
                    v-model="monitoringProgrammeInversion.messageType"
                    @change="warSetChangeFour"
                  >
                    <div>
                      <el-checkbox
                        v-for="(item, index) in DetectionSchemeSelect"
                        :label="item.id"
                        :key="index"
                      >
                        {{ item.name }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="tab-content" v-if="selectedLi == '报告生成规则'">
      <el-card shadow="never" class="report-info" v-loading="reportLoading">
        <div class="titleWarning">
          <div class="titleWar">
            <Title>报告生成规则</Title>
            <div class="bianji" @click="reportSetting" v-if="!reportControl">
              <img src="@/assets/img/lvchaofeng/set/bianjiTP.png" alt="" />
              <span>编辑</span>
            </div>
          </div>
          <div class="button" v-if="reportControl">
            <div class="search" @click="cancelReportSeting">取消</div>
            <div class="reset" @click="editReportGenerationRules">保存</div>
          </div>
        </div>
        <!-- 显示 -->
        <div class="reportDis" v-if="!reportControl">
          <div class="report-report">
            <div class="report-title">设置人员</div>
            <span>{{ personnel }}</span>
          </div>
          <div class="report-report">
            <div class="report-title">报告生成时间段</div>
            <span v-for="inx in groupRadio" :key="inx">
              {{ groupRadioList[inx] }}
            </span>
          </div>
          <div class="report-report">
            <div class="report-title">接收时间段</div>
            <span>
              {{
                reportDateVal
                  ? reportDateVal[0] + " 至 " + reportDateVal[1]
                  : "未设置"
              }}
            </span>
          </div>
          <div class="report-report">
            <div class="report-title">备注</div>
            <span>{{ textarea || "无" }}</span>
          </div>
        </div>
        <!-- 编辑 -->
        <div v-if="reportControl">
          <div class="report-report">
            <span>*</span>
            <div class="report-title">设置人员</div>
            <el-input
              class="login-input"
              placeholder="请输入人员名称"
              v-model="personnel"
              style="width: 370px"
              disabled="disabled"
              clearable
            ></el-input>
          </div>
          <div class="report-report">
            <span>*</span>
            <div class="report-title">报告生成时间段</div>
            <!-- <el-radio-group v-model="groupRadio">
              <el-radio :label="1">日报</el-radio>
              <el-radio :label="2">周报</el-radio>
              <el-radio :label="3">月报</el-radio>
              <el-radio :label="4">季报</el-radio>
              <el-radio :label="5">年报</el-radio>
            </el-radio-group> -->
            <el-checkbox-group @change="DuoXuanTimeFn" v-model="groupRadio">
              <el-checkbox :label="1">日报</el-checkbox>
              <el-checkbox :label="2">周报</el-checkbox>
              <el-checkbox :label="3">月报</el-checkbox>
              <el-checkbox :label="4">季报</el-checkbox>
              <el-checkbox :label="5">年报</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="report-report">
            <span>*</span>
            <div class="report-title">接收时间段</div>

            <el-time-picker
              v-model="reportDateVal"
              is-range
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              value-format="HH:mm:ss"
              style="width: 370px"
            ></el-time-picker>
          </div>
          <div class="report-report">
            <span style="color: transparent">*</span>
            <div class="report-title">备注</div>
            <el-input
              class="report-textarea"
              type="textarea"
              :rows="8"
              placeholder="请输入内容"
              style="width: 370px"
              v-model="textarea"
            ></el-input>
          </div>
        </div>
      </el-card>
    </div>
    <div class="tab-content" v-if="selectedLi == '登录日志'">
      <el-card shadow="never" class="login-info">
        <el-input
          class="login-input"
          placeholder="请输入账号名称"
          prefix-icon="el-icon-search"
          v-model="loginInput"
          clearable
        ></el-input>
        <div class="login-select">
          <div class="login-select-label">登录方式选择</div>
          <el-select
            class="login-select-box"
            v-model="loginSelect"
            placeholder="请选择登录方式"
            clearable
          >
            <el-option
              v-for="item in logOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="login-date">
          <div class="login-date-label">查询时间</div>
          <el-date-picker
            class="login-date-box"
            v-model="loginDate"
            type="datetimerange"
            style="width: 370px"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            range-separator="至"
            clearable
          ></el-date-picker>
        </div>
        <div class="queryBtn" @click="logQuery">查询</div>
        <div class="resetBtn" @click="logRemake">重置</div>
        <div class="exportBtn" @click="reportLoginData">
          <img src="@/assets/img/zhang/export.png" alt="" />
          <span>数据导出</span>
        </div>
      </el-card>
      <el-card shadow="never" class="login-table">
        <el-table
          v-loading="logLoading"
          :data="loginData"
          stripe
          :header-cell-style="{
            'text-align': 'center',
            background: '#F6F7FA',
            color: '#333',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="
            width: 100%;
            text-align: center;
            border: 1px solid #e4e6ec;
            border-bottom: none;
          "
          :row-class-name="tableLogClassName"
        >
          <el-table-column prop="createTime" label="登录时间"></el-table-column>
          <el-table-column
            prop="terminalTypeName"
            label="终端类型"
          ></el-table-column>
          <el-table-column prop="userName" label="账号名称"></el-table-column>
          <el-table-column
            prop="loginTypeName"
            label="登陆方式"
          ></el-table-column>
          <el-table-column prop="macAdds" label="MAC地址"></el-table-column>
          <el-table-column prop="ip" label="登录IP"></el-table-column>
          <el-table-column prop="address" label="所在地区"></el-table-column>
        </el-table>
        <Pagination
          :pageNumber="page.pageNumber"
          :pageSize="page.pageSize"
          :total="total"
          @SizeChange="SizeChange"
          @CurrentChange="CurrentChange"
        ></Pagination>
      </el-card>
    </div>
    <!-- 操作日志 -->
    <div class="tab-content" v-if="selectedLi == '操作日志'">
      <el-card shadow="never" class="work-info">
        <el-input
          class="work-input"
          placeholder="请输入操作人员"
          prefix-icon="el-icon-search"
          v-model="workInput"
          clearable
        ></el-input>
        <div class="work-select">
          <div class="work-select-label">操作类型</div>
          <el-select
            class="work-select-box"
            v-model="workSelect"
            placeholder="请选择操作类型"
            clearable
          >
            <el-option
              v-for="item in workOptions"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            ></el-option>
          </el-select>
        </div>
        <div class="work-date">
          <div class="work-date-label">操作时间</div>
          <el-date-picker
            class="work-date-box"
            v-model="workDate"
            style="width: 370px"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            range-separator="至"
            clearable
          ></el-date-picker>
        </div>
        <div class="queryBtn" @click="workQuery">查询</div>
        <div class="resetBtn" @click="workRemake">重置</div>
        <div class="exportBtn" @click="reportWorkData">
          <img src="@/assets/img/zhang/export.png" alt="" />
          <span>数据导出</span>
        </div>
      </el-card>
      <el-card shadow="never" class="work-table">
        <el-table
          :data="workData"
          stripe
          :header-cell-style="{
            'text-align': 'center',
            background: '#F6F7FA',
            color: '#333',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="
            width: 100%;
            text-align: center;
            border: 1px solid #e4e6ec;
            border-bottom: none;
          "
          v-loading="workLoading"
        >
          <el-table-column
            prop="code"
            sortable
            label="操作编号"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            sortable
            label="操作时间"
          ></el-table-column>
          <el-table-column prop="userName" label="操作人员"></el-table-column>
          <el-table-column
            prop="businessTypeName"
            label="操作类型"
          ></el-table-column>
          <el-table-column prop="ip" label="登录IP"></el-table-column>
          <el-table-column label="更多">
            <template slot-scope="scope">
              <div>
                <span
                  style="color: #2e59ec; cursor: pointer"
                  @click="openWorkDialog(scope.row)"
                >
                  查看
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :pageNumber="page.pageNumber"
          :pageSize="page.pageSize"
          :total="total"
          @SizeChange="SizeChange"
          @CurrentChange="CurrentChange"
        ></Pagination>
      </el-card>
    </div>
    <!-- 我的标签 -->
    <div class="tab-content" v-if="selectedLi == '我的标签'">
      <el-card shadow="never" class="tag-info">
        <el-input
          class="tag-input"
          placeholder="请输入标签名"
          v-model="tagInput"
          clearable
        ></el-input>
        <div class="tag-date">
          <div class="tag-date-label">操作时间</div>
          <el-date-picker
            class="tag-date-box"
            v-model="tagDate"
            style="width: 370px"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            range-separator="至"
            clearable
          ></el-date-picker>
        </div>
        <div class="queryBtn" @click="tagQuery">查询</div>
        <div class="queryBtn" style="width: 82px" @click="addTagQuery">
          新增标签
        </div>
      </el-card>
      <el-card shadow="never" class="tag-table">
        <el-table
          :data="tagData"
          stripe
          :header-cell-style="{
            'text-align': 'center',
            background: '#F6F7FA',
            color: '#333',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="
            width: 100%;
            text-align: center;
            border: 1px solid #e4e6ec;
            border-bottom: none;
          "
          v-loading="tagLoading"
        >
          <el-table-column prop="name" label="标签名称"></el-table-column>
          <el-table-column prop="createTime" label="设置时间"></el-table-column>
          <el-table-column prop="createUser" label="设置人员"></el-table-column>
          <el-table-column prop="count" label="标签计数量"></el-table-column>
          <el-table-column prop="remarks" label="备注"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div>
                <span
                  style="color: #2e59ec; cursor: pointer"
                  @click="editMyTag(scope.row)"
                >
                  编辑
                </span>
                <span class="span-cut-off">|</span>
                <span
                  style="color: #ea3342; cursor: pointer"
                  @click="openMyTag(scope.row)"
                >
                  删除
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :pageNumber="page.pageNumber"
          :pageSize="page.pageSize"
          :total="total"
          @SizeChange="SizeChange"
          @CurrentChange="CurrentChange"
        ></Pagination>
      </el-card>
    </div>
    <!-- 我的收藏 -->
    <div class="tab-content" v-if="selectedLi == '我的收藏'">
      <div class="collect-type">
        <ul class="coll-tab">
          <li
            @click="collSwitchLi(item)"
            :class="collSelectedLi == item ? 'collSelectedLi' : ''"
            v-for="item in collTabList"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
        <!-- 我的收藏动态组件 -->
        <component :is="myFavoriteComponents" :IfTitle="false"></component>
      </div>
    </div>
    <!-- 错误表述词库 -->
    <CwbsckVue v-show="selectedLi == '错误表述词库'"></CwbsckVue>
    <!-- 错误表述账号 -->
    <CwAccount v-show="selectedLi == '错误表述账号'"></CwAccount>
    <!-- 编辑我的标签弹框 -->
    <el-dialog :title="tagTitle" :visible.sync="myTagVisible" width="30%">
      <div class="edit-tag-dialog">
        <el-form :model="tagForm" label-width="80px">
          <el-form-item label="标签名称" :required="true">
            <el-input v-model="tagForm.editTagName"></el-input>
          </el-form-item>
          <el-form-item label="备注" :required="true">
            <el-input
              type="textarea"
              :rows="5"
              v-model="tagForm.editTagRemake"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="myTagVisible = false">取 消</el-button>
        <el-button type="primary" @click="myTagEdit" v-if="tagTitle == '编辑'"
          >确 定</el-button
        >
        <el-button type="primary" @click="myTagAdd" v-loading="addTagLoading" :disabled="addTagLoading" v-else>确 定</el-button>
      </div>
    </el-dialog>
    <!-- 操作日志详情弹窗 -->
    <el-dialog
      :visible.sync="workDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="630px"
    >
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">操作详情</span>
      </div>
      <div class="dialog-body">
        <el-descriptions
          class="margin-top"
          :column="1"
          :label-style="workTableHeader"
          border
        >
          <el-descriptions-item label="所属地区">
            {{ workDetails.address || "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="所属机构">
            {{ workDetails.orgName || "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="操作人员">
            {{ workDetails.userName || "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="操作时间">
            {{ workDetails.createTime || "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="操作模块">
            {{ workDetails.interfaceDescription || "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="操作类型">
            {{ workDetails.businessTypeName || "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="操作详情">
            {{
              workDetails.productTypeName +
              "系统" +
              workDetails.orgName +
              "机构人员-" +
              workDetails.userName +
              "-在" +
              workDetails.createTime +
              workDetails.businessTypeName +
              workDetails.interfaceDescription
            }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="workDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除二次确认框 -->
    <deleteDialog ref="openDialog" @deleteFn="myTagDelete"></deleteDialog>
  </div>
</template>

<script>
import deleteDialog from "@/components/deleteDialog.vue";
import Pagination from "@/components/pagination.vue";
import Title from "./components/Title.vue";
import Setup from "@/api/setUp/index";
import Error from "@/api/error/index";
import harmfulInf from "./components/harmful/index.vue";
import errorFor from "./components/error/index.vue";
import specialAct from "./components/special/information.vue";
import monitorInf from "./components/monitor/right.vue";
import publishingcontent from '../publishingcontent/index.vue' 
import CwbsckVue from './cwbsck.vue';
import CwAccount from './cwAccount.vue';
export default {
  components: {
    Title,
    Pagination,
    deleteDialog,
    harmfulInf,
    errorFor,
    specialAct,
    monitorInf,
    publishingcontent,
    CwbsckVue,
    CwAccount,
  },
  data() {
    return {
      // 操作详情表头样式
      workTableHeader: {
        "text-align": "center",
        width: "80px",
      },
      tagTitle: "新增", //我的标签标题
      reportLoading: false, //报告生成规则加载效果
      reportControl: false, //报告生成规则控制显示
      myFavoriteComponents: "", //我的收藏动态组件
      myTagId: null, //编辑我的标签数据ID
      tagLoading: false, //我的标签加载效果
      workDetails: {}, //操作详情
      workDialog: false, //操作日志详情弹窗
      workOptions: [], //操作类型选项
      workLoading: false, //操作日志加载效果
      logLoading: false, //登录日志加载效果
      warnLoading: false, //预警设置加载效果
      misrepresentationInversion: {}, // 错误表述设置反显
      misrepresentationInversionContrast: {},
      harmfulInformationInversion: {}, // 有害信息设置反显
      harmfulInformationInversionContrast: {},
      abnormalStateInversion: {}, // 异常状态设置反显
      abnormalStateInversionContrast: {},
      monitoringProgrammeInversion: {}, // 监测方案设置反显
      monitoringProgrammeInversionContrast: {},
      inverseDisplay: [], //反显显示中间数组
      DetectionSchemeSelect: [], //监测方案事件选项
      MisrepresentationSelect: [], //错误表述事件选项
      warnSelectList: [], //有害信息或异常状态事件选项
      warningSettingControl: false, //预警设置显示编辑控制
      warnCheckAllFour: false, //预警事件全选
      warnCheckAllThree: false, //预警事件全选
      warnCheckAllTwo: false, //预警事件全选
      warnSelect: [], //预警事件选中
      warnCheckAll: false, //预警事件全选
      orgId: null, //机构id
      administratorLoading: false, // 基本信息管理员列表加载效果
      essentialInformation: {}, // 机构基本信息
      tabList: JSON.parse(window.localStorage.getItem("isWangxinban")) ?
      [
        "基本信息",
        "登录日志",
        "操作日志",
        "我的标签",
        "我的收藏",
      ] : [
        "基本信息",
        "预警规则",
        "报告生成规则",
        "登录日志",
        "操作日志",
        "我的标签",
        "我的收藏",
        "错误表述词库",
        "错误表述账号",
      ], //标题列表
      selectedLi: this.$store.state.activeName, //默认选中的tab
      collTabList: ["有害信息", "错误表述", "信息监测", "专项行动",'发布内容'], //我的收藏标题列表
      collSelectedLi: this.$store.state.myFavoriteActiveName, //默认选中的tab
      tableData: [], //基本信息管理员数据
      loginData: [], //登录日志数据
      workData: [], //操作日志数据
      tagData: [], //我的标签数据
      personnel: JSON.parse(localStorage.getItem("user")).name, //人员
      // 登陆方式
      logOptions: [
        { value: 1, label: "微信扫码" },
        { value: 2, label: "短信验证" },
      ],
      groupRadio: [], // 多选时间
      groupRadioList: {
        1: "日报",
        2: "周报",
        3: "月报",
        4: "季报",
        5: "年报",
      },
      // groupRadio: 1, //单选时间
      reportDateVal: null, //报告设置时间段
      textarea: "", //文本域
      reportId: null, //报告设置数据id
      loginInput: "", //登录日志input
      loginSelect: "", //登陆日志select
      loginDate: null, //登陆日志date
      workInput: "", //操作日志input
      workSelect: "", //操作日志select
      workDate: null, //操作日志date
      tagInput: "", //我的标签input
      tagDate: null, //我的标签date
      myTagVisible: false, //是否显示编辑我的标签弹框
      tagForm: {
        editTagName: "", //编辑我的标签名
        editTagRemake: "", //编辑我的标签备注
      },
      addTagLoading:false,//添加时的loading
      page: {
        pageNumber: 1,
        pageSize: 10,
      }, //分页数据
      total: 0,
    };
  },
  mounted() {},
  created() {
    this.switchLi(this.selectedLi);
  },
  methods: {
    // 报告生成时间段
    DuoXuanTimeFn() {
    },
    // 导出操作日志
    reportWorkData() {
      let data = {
        limit: this.page.pageSize,
        offset: this.page.pageNumber,
        orgId: JSON.parse(localStorage.getItem("user")).orgId,
        productType: 5,
      };
      if (this.workInput != "") {
        data.name = this.workInput;
      }
      if (this.workSelect != "") {
        data.businessType = this.workSelect;
      }
      if (this.workDate) {
        data.beginTime = this.workDate[0];
        data.endTime = this.workDate[1];
      }
      let textName = "属地操作日志.xlsx";
      let url = "module-web/sysLog/workLogExportExcel";
      this.$parent.downloadPublicFun({
        url,
        data,
        textName,
      });
    },
    // 导出登录日志
    reportLoginData() {
      let data = {
        limit: this.page.pageSize,
        offset: this.page.pageNumber,
        orgId: JSON.parse(localStorage.getItem("user")).orgId,
        productType: 5,
      };
      if (this.loginInput != "") {
        data.name = this.loginInput;
      }
      if (this.loginSelect != "") {
        this.loginSelect == 1
          ? (data.loginType = 0)
          : (data.loginType = this.loginSelect);
      }
      if (this.loginDate) {
        data.beginTime = this.loginDate[0];
        data.endTime = this.loginDate[1];
      }
      let textName = "属地登录日志.xlsx";
      let url = "module-web/sysLog/loginLogExportExcel";
      this.$parent.downloadPublicFun({
        url,
        data,
        textName,
      });
    },
    // 报告生成规则修改
    async editReportGenerationRules() {
      if (!this.reportDateVal) {
        this.$message.warning("请选择接收时间段！");
        return false;
      }
      this.reportLoading = true;
      let data = {
        userId: JSON.parse(localStorage.getItem("user")).userId,
        name: JSON.parse(localStorage.getItem("user")).name,
        orgId: JSON.parse(localStorage.getItem("user")).orgId,
        timeType: this.groupRadio
          .filter((v) => typeof v === "number")
          .join(","),
        startTime: this.reportDateVal[0],
        endTime: this.reportDateVal[1],
      };
      if (this.reportId) {
        data.id = this.reportId;
      }
      if (this.textarea != "") {
        data.remake = this.textarea;
      }
      const res = await Setup.editReportGenerationRules(data);
      if (res.data.code == 200) {
        this.reportLoading = false;
        this.reportControl = false;
        this.getReportGenerationRules();
        this.$message.success("修改成功！");
      }
    },
    // 获取报告生成规则
    async getReportGenerationRules() {
      this.reportLoading = true;
      const res = await Setup.getReportGenerationRules();
      if (res.data.code == 200) {
        if (res.data.data) {
          this.reportId = res.data.data.id;
          if (res.data.data.timeType) {
            this.groupRadio = res.data.data.timeType
              .split(",")
              .map((v) => Number(v));
          } else {
            this.groupRadio = [];
          }
          if (res.data.data.startTime && res.data.data.endTime) {
            this.reportDateVal = [
              res.data.data.startTime,
              res.data.data.endTime,
            ];
          } else {
            this.reportDateVal = null;
          }
          if (res.data.data.remake) {
            this.textarea = res.data.data.remake;
          } else {
            this.textarea = "";
          }
        }
        this.reportLoading = false;
      } else {
        this.reportLoading = false;
      }
    },
    // 取消编辑生成报告规则
    cancelReportSeting() {
      this.reportControl = !this.reportControl;
      this.getReportGenerationRules();
    },
    // 报告生成规则显示或编辑
    reportSetting() {
      this.reportControl = !this.reportControl;
      // this.groupRadio =  this.groupRadio.map(v => Number(v))
    },
    // 我的标签修改
    async myTagEdit() {
      if (this.tagForm.editTagName == "") {
        this.$message.warning("请输入标签名称！");
        return false;
      }
      if (this.tagForm.editTagRemake == "") {
        this.$message.warning("请输入标签备注！");
        return false;
      }
      let data = {
        id: this.myTagId,
        name: this.tagForm.editTagName,
        remarks: this.tagForm.editTagRemake,
      };
      const res = await Setup.myTagEdit(data);
      if (res.data.code == 200) {
        this.myTagVisible = false;
        this.getMyTagsList();
        this.$message.success("修改成功！");
      }
    },
    // 我的标签新增
    async myTagAdd() {
      if (this.tagForm.editTagName == "") {
        this.$message.warning("请输入标签名称！");
        return false;
      }
      if (this.tagForm.editTagRemake == "") {
        this.$message.warning("请输入标签备注！");
        return false;
      }
      let data = {
        orgId: JSON.parse(localStorage.getItem("user")).orgId,
        name: this.tagForm.editTagName,
        remarks: this.tagForm.editTagRemake,
      };
      this.addTagLoading = true
      const res = await Setup.myTagAdd(data);
      if (res.data.code == 200) {
        this.myTagVisible = false;
        this.getMyTagsList();
        this.$message.success("新增成功！");
        this.addTagLoading = false
      }
      else{
        this.addTagLoading = false
      }
    },
    // 我的标签数据删除
    async myTagDelete(item) {
      this.tagLoading = true
      const res = await Setup.myTagDelete(item.item.id);
      if (res.data.code == 200) {
        this.$message.success("删除成功！");
        this.tagLoading = false
        this.getMyTagsList();
      }
    },
    // 打开我的标签删除弹窗
    openMyTag(item) {
      item.message = "此条我的标签数据";
      this.$refs.openDialog.openDialog(item);
    },
    // 我的标签查询
    tagQuery() {
      this.total = 0;
      this.page.pageSize = 10;
      this.page.pageNumber = 1;
      this.getMyTagsList();
    },
    // 获取我的标签数据
    async getMyTagsList() {
      this.tagLoading = true;
      let data = {
        limit: this.page.pageSize,
        page: this.page.pageNumber,
      };
      if (this.tagInput != "") {
        data.name = this.tagInput;
      }
      if (this.tagDate) {
        data.beginTime = this.tagDate[0];
        data.endTime = this.tagDate[1];
      }
      const res = await Setup.getMyTagsList(data);
      if (res.data.code == 200) {
        this.total = res.data.data.totalCount / 1;
        this.tagData = res.data.data.list;
        this.tagData.map((x) => {
          x.count = 10;
        });
        this.tagLoading = false;
      } else {
        this.total = 0;
        this.tagData = [];
        this.tagLoading = false;
      }
    },
    // 打开操作日志详情弹窗
    openWorkDialog(item) {
      this.workDetails = item;
      this.workDialog = true;
    },
    // 操作日志查询
    workQuery() {
      this.total = 0;
      this.page.pageSize = 10;
      this.page.pageNumber = 1;
      this.getWorkList();
    },
    // 操作日志重置
    workRemake() {
      this.total = 0;
      this.page.pageSize = 10;
      this.page.pageNumber = 1;
      this.workInput = "";
      this.workSelect = "";
      this.workDate = null;
      this.getWorkList();
    },
    // 获取操作日志
    async getWorkList() {
      this.workLoading = true;
      let data = {
        limit: this.page.pageSize,
        page: this.page.pageNumber,
        productType: 5,
      };
      if (this.workInput != "") {
        data.name = this.workInput;
      }
      if (this.workSelect != "") {
        data.businessType = this.workSelect;
      }
      if (this.workDate) {
        data.beginTime = this.workDate[0];
        data.endTime = this.workDate[1];
      }
      const res = await Setup.getWorkList(data);
      if (res.data.code == 200) {
        this.total = res.data.data.totalCount;
        this.workData = res.data.data.list;
        this.workLoading = false;
      } else {
        this.total = 0;
        this.workData = [];
        this.workLoading = false;
      }
    },
    // 获取操作类型
    async getWorkTypeList() {
      const res = await Setup.getWorkTypeList();
      if (res.data.code == 200) {
        this.workOptions = res.data.data.businessTypes;
      }
    },
    // 登陆方式查询
    logQuery() {
      this.total = 0;
      this.page.pageSize = 10;
      this.page.pageNumber = 1;
      this.getLoginLogList();
    },
    // 登录日志重置
    logRemake() {
      this.total = 0;
      this.page.pageSize = 10;
      this.page.pageNumber = 1;
      this.loginInput = "";
      this.loginSelect = "";
      this.loginDate = null;
      this.getLoginLogList();
    },
    // 异常登录样式
    tableLogClassName({ row }) {
      if (row.abnormalLogin == 1) {
        return "Login-row";
      }
    },
    // 获取登录日志
    async getLoginLogList() {
      this.logLoading = true;
      let data = {
        limit: this.page.pageSize,
        page: this.page.pageNumber,
        productType: 5,
      };
      if (this.loginInput != "") {
        data.name = this.loginInput;
      }
      if (this.loginSelect != "") {
        this.loginSelect == 1
          ? (data.loginType = 0)
          : (data.loginType = this.loginSelect);
      }
      if (this.loginDate) {
        data.beginTime = this.loginDate[0];
        data.endTime = this.loginDate[1];
      }
      const res = await Setup.getLoginLogList(data);
      if (res.data.code == 200) {
        this.total = res.data.data.totalCount;
        this.loginData = res.data.data.list;
        this.logLoading = false;
      } else {
        this.total = 0;
        this.loginData = [];
        this.logLoading = false;
      }
    },
    // 保存预警设置
    async saveAlertRules() {
      this.warnLoading = true;
      let data = [];
      // data.push(this.misrepresentationInversion);
      // data.push(this.harmfulInformationInversion);
      // data.push(this.abnormalStateInversion);
      // data.push(this.monitoringProgrammeInversion);
      // data = data.filter(v=> v.isWarning == 1)
      // data = data.filter(v=> v.messageType.length > 0 && v.messageType)
      // let ifNo = null
    
      // data.forEach(v=> {
      //   console.log(v.messageType.length == 0)
      //   if(!v.warningTime || v.warningTime.length == 0 || v.messageType.length == 0 || !v.messageType) {
      //     ifNo = true
      //     return
      //   }
      // })
      // if(ifNo) {
      //   this.$message.warning("请选择预警时间段和预警设置！");
      //   this.warnLoading = false;
      //   return
      // }
      // 错误表述提交参数
      
      this.misrepresentationInversion.messageType =
        this.misrepresentationInversion.messageType.join(",");
      this.misrepresentationInversion.isWarning =
        this.misrepresentationInversion.isWarning / 1;
      this.misrepresentationInversion.startTime = this
        .misrepresentationInversion.warningTime
        ? this.misrepresentationInversion.warningTime[0] 
        : "";
      this.misrepresentationInversion.endTime = this.misrepresentationInversion
        .warningTime
        ? this.misrepresentationInversion.warningTime[1]
        : "";
      this.misrepresentationInversion.userName = JSON.parse(
        localStorage.getItem("user")
      ).name;
      this.misrepresentationInversion.userId = JSON.parse(
        localStorage.getItem("user")
      ).userId;
      this.$delete(this.misrepresentationInversion, "selectedEventsArr");
      this.$delete(this.misrepresentationInversion, "warningTime");
      if (!this.misrepresentationInversion.id) {
        this.$delete(this.misrepresentationInversion, "id");
      }
      // 有害信息提交参数
      this.harmfulInformationInversion.messageType =
        this.harmfulInformationInversion.messageType.join(",");
      this.harmfulInformationInversion.isWarning =
        this.harmfulInformationInversion.isWarning / 1;
      this.harmfulInformationInversion.startTime = this
        .harmfulInformationInversion.warningTime
        ? this.harmfulInformationInversion.warningTime[0]
        : "";
      this.harmfulInformationInversion.endTime = this
        .harmfulInformationInversion.warningTime
        ? this.harmfulInformationInversion.warningTime[1]
        : "";
      this.harmfulInformationInversion.userName = JSON.parse(
        localStorage.getItem("user")
      ).name;
      this.harmfulInformationInversion.userId = JSON.parse(
        localStorage.getItem("user")
      ).userId;
      this.$delete(this.harmfulInformationInversion, "selectedEventsArr");
      this.$delete(this.harmfulInformationInversion, "warningTime");
      if (!this.harmfulInformationInversion.id) {
        this.$delete(this.harmfulInformationInversion, "id");
      }
      // 异常状态提交参数
      this.abnormalStateInversion.messageType =
        this.abnormalStateInversion.messageType.join(",");
      this.abnormalStateInversion.isWarning =
        this.abnormalStateInversion.isWarning / 1;
      this.abnormalStateInversion.startTime = this.abnormalStateInversion
        .warningTime
        ? this.abnormalStateInversion.warningTime[0]
        : "";
      this.abnormalStateInversion.endTime = this.abnormalStateInversion
        .warningTime
        ? this.abnormalStateInversion.warningTime[1]
        : "";
      this.abnormalStateInversion.userName = JSON.parse(
        localStorage.getItem("user")
      ).name;
      this.abnormalStateInversion.userId = JSON.parse(
        localStorage.getItem("user")
      ).userId;
      this.$delete(this.abnormalStateInversion, "selectedEventsArr");
      this.$delete(this.abnormalStateInversion, "warningTime");
      if (!this.abnormalStateInversion.id) {
        this.$delete(this.abnormalStateInversion, "id");
      }
      // 检测方案提交参数
      this.monitoringProgrammeInversion.messageType =
        this.monitoringProgrammeInversion.messageType.join(",");
      this.monitoringProgrammeInversion.isWarning =
        this.monitoringProgrammeInversion.isWarning / 1;
      this.monitoringProgrammeInversion.startTime = this
        .monitoringProgrammeInversion.warningTime
        ? this.monitoringProgrammeInversion.warningTime[0]
        : null;
      this.monitoringProgrammeInversion.endTime = this
        .monitoringProgrammeInversion.warningTime
        ? this.monitoringProgrammeInversion.warningTime[1]
        : null;
      this.monitoringProgrammeInversion.userName = JSON.parse(
        localStorage.getItem("user")
      ).name;
      this.monitoringProgrammeInversion.userId = JSON.parse(
        localStorage.getItem("user")
      ).userId;
      this.$delete(this.monitoringProgrammeInversion, "selectedEventsArr");
      this.$delete(this.monitoringProgrammeInversion, "warningTime");
      if (!this.monitoringProgrammeInversion.id) {
        this.$delete(this.monitoringProgrammeInversion, "id");
      }
      // data = [];
      if (
        JSON.stringify(this.misrepresentationInversion) !==
        JSON.stringify(this.misrepresentationInversionContrast)
      ) {
        data.push(this.misrepresentationInversion);
      }
      if (
        JSON.stringify(this.harmfulInformationInversion) !==
        JSON.stringify(this.harmfulInformationInversionContrast)
      ) {
        data.push(this.harmfulInformationInversion);
      }
      if (
        JSON.stringify(this.abnormalStateInversion) !==
        JSON.stringify(this.abnormalStateInversionContrast)
      ) {
        data.push(this.abnormalStateInversion);
      }
      if (
        JSON.stringify(this.monitoringProgrammeInversion) !==
        JSON.stringify(this.monitoringProgrammeInversionContrast)
      ) {
        data.push(this.monitoringProgrammeInversion);
      }

     
      // data.push(this.misrepresentationInversion);
      // data.push(this.harmfulInformationInversion);
      // data.push(this.abnormalStateInversion);
      // data.push(this.monitoringProgrammeInversion);
      // const isWarningList = data.filter(v=> v.isWarning == 0)
      // if(isWarningList.length <4) {
      //   data = data.filter(v=> v.isWarning == 1)
      //   data = data.filter(v=> v.messageType.length > 0 && v.messageType)
      
      // if (data.length == 0) { 
      //   this.$message.warning("未修改任何数据，提交无效！");
      //   this.cancelWarningSeting();
      //   return false;
      // }
      // }else{
      //   this.$message.warning("全部未预警！");
      //   this.cancelWarningSeting();
      //   return false;
      // }
        
      if(data.length == 0) {
        this.warnLoading = false;
        this.warningSettingControl = false;
        this.getMechanismWarningRules();
        this.$message.warning("未修改任何数据，提交无效！");
        return
      }
      
      const res = await Setup.saveAlertRules(data);
      if (res.data.code == 200) {
        this.warnLoading = false;
        this.warningSettingControl = false;
        this.getMechanismWarningRules();
        this.$message.success("修改成功！");
      }
    },
    // 取消预警编辑
    cancelWarningSeting() {
      this.getMechanismWarningRules();
      this.warningSettingControl = !this.warningSettingControl;
    },
    // 编辑预警设置
    editWarningSetting() {
      this.warningSettingControl = !this.warningSettingControl;
    },
    // 预警设置全选
    warSetAllChange(val) {
      let checked = this.MisrepresentationSelect.map((item) => {
        return item.id;
      });
      this.misrepresentationInversion.messageType = val ? checked : [];
      this.isIndeterminate = false;
    },
    // 预警设置多选
    warSetChange(value) {
      let checkedCount = value.length;
      this.warnCheckAll = checkedCount === this.MisrepresentationSelect.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.MisrepresentationSelect.length;
    },
    // 预警设置全选二
    warSetAllChangeTwo(val) {
      let checked = this.warnSelectList.map((item) => {
        return item.value;
      });
      this.harmfulInformationInversion.messageType = val ? checked : [];
      this.isIndeterminate = false;
    },
    // 预警设置多选二
    warSetChangeTwo(value) {
      let checkedCount = value.length;
      this.warnCheckAllTwo = checkedCount === this.warnSelectList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.warnSelectList.length;
    },
    // 预警设置全选三
    warSetAllChangeThree(val) {
      let checked = this.warnSelectList.map((item) => {
        return item.value;
      });
      this.abnormalStateInversion.messageType = val ? checked : [];
      this.isIndeterminate = false;
    },
    // 预警设置多选三
    warSetChangeThree(value) {
      let checkedCount = value.length;
      this.warnCheckAllThree = checkedCount === this.warnSelectList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.warnSelectList.length;
    },
    // 预警设置全选四
    warSetAllChangeFour(val) {
      let checked = this.DetectionSchemeSelect.map((item) => {
        return item.id;
      });
      this.monitoringProgrammeInversion.messageType = val ? checked : [];
      this.isIndeterminate = false;
    },
    // 预警设置多选四
    warSetChangeFour(value) {
      let checkedCount = value.length;
      this.warnCheckAllFour =
        checkedCount === this.DetectionSchemeSelect.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.DetectionSchemeSelect.length;
    },
    // 获取有害信息或异常状态事件选项
    async getErrorTypeList() {
      const res = await Error.getErrorTypeList();
      if (res.data.code == 200) {
        this.warnSelectList = res.data.data;
      }
    },
    // 获取错误表述事件选项
    async getMisrepresentationSelect() {
      const res = await Error.getMisrepresentationSelect();

      if (res.data.code == 200) {
        this.MisrepresentationSelect = res.data.data;
      }
    },
    // 获取监测方案事件选项
    async getDetectionSchemeSelect() {
      const res = await Setup.getDetectionSchemeSelect();
      if (res.data.code == 200) {
        this.DetectionSchemeSelect = res.data.data;
      }
    },
    // 获取机构管理员列表
    async getMechanismAdmList() {
      this.administratorLoading = true;
      const res = await Setup.getMechanismAdmList({
        limit: this.page.pageSize,
        page: this.page.pageNumber,
      });
      if (res.data.code == 200) {
        this.total = res.data.data.totalCount;
        this.tableData = res.data.data.list;
        this.administratorLoading = false;
      }
    },
    // 获取用户基本信息
    async getUserInformation() {
      const res = await Setup.getUserInformation();
      if (res.data.code == 200) {
        this.orgId = res.data.data.orgId;
        this.getMechanismInformation(res.data.data.orgId);
      }
    },
    // 强制刷新时间组件
    changeDate() {
      this.$forceUpdate();
    },
    // 获取机构预警规则
    async getMechanismWarningRules() {
      this.warnLoading = true;
      const res = await Setup.getMechanismWarningRules();
      if (res.data.code == 200) {
        //warnType值 错误表述	1  异常状态	2  有害信息	3  监测方案	4
        this.inverseDisplay = JSON.parse(JSON.stringify(res.data.data));
        this.inverseDisplay.forEach((x) => {
          // 错误表述反显
          if (x.warnType == 1) {
            this.misrepresentationInversion = x;
            this.misrepresentationInversionContrast = JSON.parse(
              JSON.stringify(x)
            );
            this.misrepresentationInversion.selectedEventsArr = [];
            this.misrepresentationInversion.isWarning = String(
              this.misrepresentationInversion.isWarning
            );
            if (
              this.misrepresentationInversion.startTime &&
              this.misrepresentationInversion.endTime
            ) {
              this.misrepresentationInversion.warningTime = [
                this.misrepresentationInversion.startTime,
                this.misrepresentationInversion.endTime,
              ];
            } else {
              this.misrepresentationInversion.warningTime = null;
            }
            if (this.misrepresentationInversion.messageType) {
              this.misrepresentationInversion.messageType.split(",").length ==
              this.MisrepresentationSelect.length
                ? (this.warnCheckAll = true)
                : (this.warnCheckAll = false);
              this.misrepresentationInversion.selectedEventsArr =
                this.misrepresentationInversion.messageTypeData;
              this.misrepresentationInversion.messageType =
                this.misrepresentationInversion.messageType
                  .split(",")
                  .map((item) => {
                    return item / 1;
                  });
            } else {
              this.misrepresentationInversion.messageType = [];
              this.misrepresentationInversion.selectedEventsArr = [];
            }
          }
          // 异常状态反显
          if (x.warnType == 2) {
            this.abnormalStateInversion = x;
            this.abnormalStateInversionContrast = JSON.parse(JSON.stringify(x));
            this.abnormalStateInversion.selectedEventsArr = [];
            this.abnormalStateInversion.isWarning = String(
              this.abnormalStateInversion.isWarning
            );
            if (
              this.abnormalStateInversion.startTime &&
              this.abnormalStateInversion.endTime
            ) {
              this.abnormalStateInversion.warningTime = [
                this.abnormalStateInversion.startTime,
                this.abnormalStateInversion.endTime,
              ];
            } else {
              this.abnormalStateInversion.warningTime = null;
            }
            if (this.abnormalStateInversion.messageType) {
              this.abnormalStateInversion.messageType.split(",").length ==
              this.warnSelectList.length
                ? (this.warnCheckAllThree = true)
                : (this.warnCheckAllThree = false);
              this.abnormalStateInversion.selectedEventsArr =
                this.abnormalStateInversion.messageTypeData;
              this.abnormalStateInversion.messageType =
                this.abnormalStateInversion.messageType.split(",");
            } else {
              this.abnormalStateInversion.messageType = [];
              this.abnormalStateInversion.selectedEventsArr = [];
            }
          }
          // 有害信息反显
          if (x.warnType == 3) {
            this.harmfulInformationInversion = x;
            this.harmfulInformationInversionContrast = JSON.parse(
              JSON.stringify(x)
            );
            this.harmfulInformationInversion.selectedEventsArr = [];
            this.harmfulInformationInversion.isWarning = String(
              this.harmfulInformationInversion.isWarning
            );
            if (
              this.harmfulInformationInversion.startTime &&
              this.harmfulInformationInversion.endTime
            ) {
              this.harmfulInformationInversion.warningTime = [
                this.harmfulInformationInversion.startTime,
                this.harmfulInformationInversion.endTime,
              ];
            } else {
              this.harmfulInformationInversion.warningTime = null;
            }
            if (this.harmfulInformationInversion.messageType) {
              this.harmfulInformationInversion.messageType.split(",").length ==
              this.warnSelectList.length
                ? (this.warnCheckAllTwo = true)
                : (this.warnCheckAllTwo = false);
              this.harmfulInformationInversion.selectedEventsArr =
                this.harmfulInformationInversion.messageTypeData;
              this.harmfulInformationInversion.messageType =
                this.harmfulInformationInversion.messageType.split(",");
            } else {
              this.harmfulInformationInversion.messageType = [];
              this.harmfulInformationInversion.selectedEventsArr = [];
            }
          }
          // 监测方案反显
          if (x.warnType == 4) {
            this.monitoringProgrammeInversion = x;
            this.monitoringProgrammeInversionContrast = JSON.parse(
              JSON.stringify(x)
            );
            this.monitoringProgrammeInversion.selectedEventsArr = [];
            this.monitoringProgrammeInversion.isWarning = String(
              this.monitoringProgrammeInversion.isWarning
            );
            if (
              this.monitoringProgrammeInversion.startTime &&
              this.monitoringProgrammeInversion.endTime
            ) {
              this.monitoringProgrammeInversion.warningTime = [
                this.monitoringProgrammeInversion.startTime,
                this.monitoringProgrammeInversion.endTime,
              ];
            } else {
              this.monitoringProgrammeInversion.warningTime = null;
            }
            if (this.monitoringProgrammeInversion.messageType) {
              this.monitoringProgrammeInversion.messageType.split(",").length ==
              this.DetectionSchemeSelect.length
                ? (this.warnCheckAllFour = true)
                : (this.warnCheckAllFour = false);
              this.monitoringProgrammeInversion.selectedEventsArr =
                this.monitoringProgrammeInversion.messageTypeData;
              this.monitoringProgrammeInversion.messageType =
                this.monitoringProgrammeInversion.messageType
                  .split(",")
                  .map((item) => {
                    return item / 1;
                  });
              this.warnLoading = false;
            } else {
              this.monitoringProgrammeInversion.messageType = [];
              this.monitoringProgrammeInversion.selectedEventsArr = [];
              this.warnLoading = false;
            }
          }
        });
      }
    },
    // 获取机构信息
    async getMechanismInformation(data) {
      const res = await Setup.getMechanismInformation(data);
      if (res.data.code == 200) {
        this.essentialInformation = res.data.data;
        if (this.essentialInformation.provinceName) {
          this.essentialInformation.orgRegion =
            this.essentialInformation.provinceName;
        }
        if (this.essentialInformation.cityName) {
          this.essentialInformation.orgRegion =
            this.essentialInformation.orgRegion +
            "-" +
            this.essentialInformation.cityName;
        }
        if (this.essentialInformation.districtName) {
          this.essentialInformation.orgRegion =
            this.essentialInformation.orgRegion +
            "-" +
            this.essentialInformation.districtName;
        }
      }
    },
    // 分页
    SizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNumber = 1;
      if (this.selectedLi == "基本信息") {
        this.getMechanismAdmList();
      }
      if (this.selectedLi == "登录日志") {
        this.getLoginLogList();
      }
      if (this.selectedLi == "操作日志") {
        this.getWorkList();
      }
      if (this.selectedLi == "我的标签") {
        this.getMyTagsList();
      }
    },
    CurrentChange(val) {
      this.page.pageNumber = val;
      if (this.selectedLi == "基本信息") {
        this.getMechanismAdmList();
      }
      if (this.selectedLi == "登录日志") {
        this.getLoginLogList();
      }
      if (this.selectedLi == "操作日志") {
        this.getWorkList();
      }
      if (this.selectedLi == "我的标签") {
        this.getMyTagsList();
      }
    },
    //选择标题
    switchLi(item) {
      this.total = 0;
      this.selectedLi = item;
      this.page.pageSize = 10;
      this.page.pageNumber = 1;
      this.loginInput = "";
      this.loginSelect = "";
      this.loginDate = null;
      this.workInput = "";
      this.workSelect = "";
      this.workDate = null;
      this.tagInput = "";
      this.tagDate = null;
      if (item == "基本信息") {
        this.getUserInformation();
        this.getMechanismAdmList();
      }
      if (item == "预警规则") {
        this.getErrorTypeList();
        this.getMisrepresentationSelect();
        this.getDetectionSchemeSelect();
        this.getMechanismWarningRules();
      }
      if (item == "报告生成规则") {
        this.getReportGenerationRules();
      }
      if (item == "登录日志") {
        this.getLoginLogList();
      }
      if (item == "操作日志") {
        this.getWorkTypeList();
        this.getWorkList();
      }
      if (item == "我的标签") {
        this.getMyTagsList();
      }
      if (item == "我的收藏") {
        this.collSwitchLi(this.collSelectedLi);
      }
      sessionStorage.setItem("activeName", item);
    },
    //我的收藏选择标题
    collSwitchLi(item) {
      this.collSelectedLi = item;
      if (item == "有害信息") {
        this.myFavoriteComponents = "harmfulInf";
      }
      if (item == "错误表述") {
        this.myFavoriteComponents = "errorFor";
      }
      if (item == "信息监测") {
        this.myFavoriteComponents = "monitorInf";
      }
      if (item == "专项行动") {
        this.myFavoriteComponents = "specialAct";
      }
      if(item == '发布内容') {
        this.myFavoriteComponents = 'publishingcontent'
       
      }
      sessionStorage.setItem("myFavoriteActiveName", item);
    },
    // 编辑我的标签
    editMyTag(row) {
      this.myTagId = row.id;
      this.tagTitle = "编辑";
      this.tagForm.editTagName = row.name;
      this.tagForm.editTagRemake = row.remarks;
      this.myTagVisible = true;
    },
    // 新增我的标签
    addTagQuery() {
      this.tagTitle = "新增";
      this.tagForm.editTagName = "";
      this.tagForm.editTagRemake = "";
      this.myTagVisible = true;
    },
  },
  destroyed() {
    this.$store.state.activeName = "基本信息";
    sessionStorage.removeItem("activeName");
    this.$store.state.myFavoriteActiveName = "有害信息";
    sessionStorage.removeItem("myFavoriteActiveName");
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table .Login-row {
  color: #ea3342 !important;
}
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}
::v-deep .el-range-separator {
  width: 24px;
}
.dialog-body {
  text-align: center;
  .dialog-body-desc {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
  }
}

.page {
  .setting-title {
    width: 104px;
    height: 37px;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 37px;
    margin-bottom: 24px;
  }
  .tab {
    display: flex;
    justify-content: start;
    width: 100%;
    height: 54px;
    background: #ffffff;
    border-radius: 4px;
    padding-left: 32px;
    margin-bottom: 16px;
    li {
      line-height: 54px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-right: 80px;
      cursor: pointer;
    }
  }
  .tab-content {
    // 基本信息
    .base-info {
      margin-bottom: 16px;
      position: relative;
      width: 100%;
      height: 250px;
      background: #ffffff;
      border-radius: 4px;
      .info-list {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        margin: 32px 0 0 25px;
        li {
          width: 420px;
          height: 20px;
          margin: 0 100px 32px 0;
          font-size: 14px;
          font-family: Helvetica;
          color: #333333;
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
      img {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .administrator {
      width: 100%;
      background: #ffffff;
      border-radius: 4px;
      .admin-list {
        padding: 32px 24px 0 24px;
      }
    }

    //预警规则
    .warning-info {
      // height: 566px;
      background: #ffffff;
      border-radius: 4px;
      ::v-deep .el-range-editor .el-range-input {
        width: 140px;
      }
      .titleWarning {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .titleWar {
          display: flex;
          align-items: center;
          .bianji {
            margin-left: 25px;
            color: #ff7c00;
            font-size: 0.875rem;
            position: relative;
            top: 3px;
            cursor: pointer;
            span {
              margin-left: 8px;
            }
          }
        }

        .button {
          display: flex;
          .search {
            display: flex;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            width: auto;
            background: #ffffff;
            border-radius: 4px;
            padding: 6px 16px;
            cursor: pointer;
            border: 1px solid #e1e1e1;
          }
          .reset {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 32px;
            background: #f58030;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
            margin-left: 15px;
          }
        }
      }
      .displayStyle {
        .displayStyleSpan {
          color: #606266;
          font-size: 14px;
          font-weight: normal;
        }
      }
      .warning-radio {
        display: flex;
        padding: 60px 20px;
        padding-top: 30px;
        padding-bottom: 20px;

        .err-radio {
          display: flex;
          margin-right: 133px;
          .title {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            color: #333333;
            margin-right: 32px;
            span {
              color: #e60b1e;
            }
          }
          .el-radio {
            padding-top: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .el-radio:nth-child(1) {
            margin-right: 60px;
          }
        }
      }
      .warning-form {
        width: 816px;
        background: #f5f6fa;
        margin-left: 20px;
        padding: 40px;
        padding-bottom: 10px;
        .warning-form-title,
        .warning-form-radio,
        .warning-form-date,
        .warning-form-checkbox {
          margin-bottom: 32px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .warning-form-radio,
        .warning-form-date,
        .warning-form-checkbox {
          margin-left: 10px;
        }
        .warning-form-title {
          span {
            color: #e60b1e;
          }
        }
        .warning-form-radio {
          .el-radio {
            margin-right: 40px;
          }
        }
        .warning-form-date {
          span {
            margin-right: 22px;
          }
          ::v-deep .el-date-editor.el-input,
          .el-date-editor.el-input__inner {
            width: 351px;
            height: 36px;
          }
          ::v-deep .el-date-editor .el-range__icon {
            margin-top: -5px;
          }
          ::v-deep .el-icon-time:before {
            content: "\e78e";
          }
          // \e78e
        }
        .warning-form-checkbox {
          display: flex;
          .warnSelect {
            width: 350px;
            border: 1px solid #dcdfe6;
            background-color: #ffffff;
            margin-left: 35px;
            .warnSelectList {
              height: 270px;
              overflow: auto;
            }
            ::v-deep .el-checkbox {
              display: block;
              width: 100%;
              height: 30px;
              line-height: 30px;
              box-sizing: border-box;
              padding-left: 10px;
            }
          }
        }
      }
    }
    //生成报告
    .report-info {
      width: 100%;
      height: 819px;
      background: #ffffff;
      border-radius: 4px;
      .reportDis {
        .report-report {
          display: flex;
          align-items: center;
          span {
            color: #606266;
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
      .titleWarning {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .titleWar {
          display: flex;
          align-items: center;
          .bianji {
            margin-left: 25px;
            color: #ff7c00;
            font-size: 0.875rem;
            position: relative;
            top: 3px;
            cursor: pointer;
            span {
              margin-left: 8px;
            }
          }
        }

        .button {
          display: flex;
          .search {
            display: flex;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            width: auto;
            background: #ffffff;
            border-radius: 4px;
            padding: 6px 16px;
            cursor: pointer;
            border: 1px solid #e1e1e1;
          }
          .reset {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 32px;
            background: #f58030;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
            margin-left: 15px;
          }
        }
      }
      .report-report {
        display: flex;
        margin-top: 40px;
        margin-left: 41px;
        span {
          color: #e60b1e;
          display: inline-block;
          padding: 2px 4px 0 0;
          font-weight: 600;
          line-height: 36px;
        }
        .report-title {
          min-width: 106px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 36px;
          text-align-last: justify;
          margin-right: 18px;
        }
        .el-radio-group {
          font-size: 0;
          margin-top: 11px;
        }
        ::v-deep .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 351px;
          height: 36px;
        }
        ::v-deep .el-date-editor .el-range__icon {
          margin-top: -5px;
        }
        ::v-deep .el-icon-time:before {
          content: "\e78e";
        }
        .report-textarea {
          width: 351px;
        }
      }
      .report-report:nth-child(1) {
        margin-top: 51px;
      }
    }
    //登陆日志
    .login-info {
      position: relative;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      ::v-deep .el-card__body {
        padding: 16px 24px;
        display: flex;
      }
      .login-input {
        width: 240px;
        margin-right: 32px;
        ::v-deep {
          .el-input__inner {
            height: 36px;
          }

          .el-input__prefix,
          .el-input__suffix {
            height: 36px;
          }
          .el-input.is-focus .el-input__inner {
            caret-color: #f58030;
            border: 1px solid #f58030 !important;
            transition: all 0.3s;
          }
        }
      }
      .login-select {
        display: flex;
        .login-select-label {
          font-weight: 600;
          line-height: 36px;
          margin-right: 12px;
        }
        .login-select-box {
          width: 190px;
          margin-right: 32px;
          ::v-deep {
            .el-input__inner {
              height: 36px;
            }

            .el-input__prefix,
            .el-input__suffix {
              height: 36px;
            }
            .el-input.is-focus .el-input__inner {
              caret-color: #f58030;
              border: 1px solid #f58030 !important;
              transition: all 0.3s;
            }
          }
        }
      }
      .login-date {
        display: flex;
        .login-date-label {
          font-weight: 600;
          line-height: 36px;
          margin-right: 12px;
        }
        .login-date-box {
          height: 36px;
          width: 280px;
          margin-right: 32px;
        }
      }
      .queryBtn {
        width: 60px;
        height: 36px;
        background-color: #f58030;
        color: white;
        line-height: 36px;
        text-align: center;
        border-radius: 4px;
        margin-right: 16px;
        cursor: pointer;
      }
      .resetBtn {
        width: 60px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 4px;
        color: #000;
        border: 1px solid #e1e1e1;
        cursor: pointer;
      }
      .exportBtn {
        position: absolute;
        right: 24px;
        width: 97px;
        height: 36px;
        line-height: 32px;
        text-align: center;
        background-color: #fff1e8;
        border-radius: 4px;
        color: #f58030;
        cursor: pointer;
        img {
          height: 14px;
          width: 14px;
          margin-right: 2px;
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
        }
      }
    }
    //操作日志
    .work-info {
      position: relative;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      ::v-deep .el-card__body {
        padding: 16px 24px;
        display: flex;
      }
      .work-input {
        width: 240px;
        margin-right: 32px;
        ::v-deep {
          .el-input__inner {
            height: 36px;
          }

          .el-input__prefix,
          .el-input__suffix {
            height: 36px;
          }
          .el-input.is-focus .el-input__inner {
            caret-color: #f58030;
            border: 1px solid #f58030 !important;
            transition: all 0.3s;
          }
        }
      }
      .work-select {
        display: flex;
        .work-select-label {
          font-weight: 600;
          line-height: 36px;
          margin-right: 12px;
        }
        .work-select-box {
          width: 190px;
          margin-right: 32px;
          ::v-deep {
            .el-input__inner {
              height: 36px;
            }

            .el-input__prefix,
            .el-input__suffix {
              height: 36px;
            }
            .el-input.is-focus .el-input__inner {
              caret-color: #f58030;
              border: 1px solid #f58030 !important;
              transition: all 0.3s;
            }
          }
        }
      }
      .work-date {
        display: flex;
        .work-date-label {
          font-weight: 600;
          line-height: 36px;
          margin-right: 12px;
        }
        .work-date-box {
          height: 36px;
          width: 280px;
          margin-right: 32px;
        }
      }
      .queryBtn {
        width: 60px;
        height: 36px;
        background-color: #f58030;
        color: white;
        line-height: 36px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .exportBtn {
        position: absolute;
        right: 24px;
        width: 97px;
        height: 36px;
        line-height: 32px;
        text-align: center;
        background-color: #fff1e8;
        border-radius: 4px;
        color: #f58030;
        cursor: pointer;
        img {
          height: 14px;
          width: 14px;
          margin-right: 2px;
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
        }
      }
    }
    //我的标签
    .tag-info {
      position: relative;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      ::v-deep .el-card__body {
        padding: 16px 24px;
        display: flex;
      }
      .tag-input {
        width: 240px;
        margin-right: 32px;
        ::v-deep {
          .el-input__inner {
            height: 36px;
          }

          .el-input__prefix,
          .el-input__suffix {
            height: 36px;
          }
          .el-input.is-focus .el-input__inner {
            caret-color: #f58030;
            border: 1px solid #f58030 !important;
            transition: all 0.3s;
          }
        }
      }
      .tag-date {
        display: flex;
        .tag-date-label {
          font-weight: 600;
          line-height: 36px;
          margin-right: 12px;
        }
        .tag-date-box {
          height: 36px;
          width: 280px;
          margin-right: 32px;
        }
      }
      .queryBtn {
        width: 60px;
        height: 36px;
        background-color: #f58030;
        color: white;
        line-height: 36px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    //我的收藏
    .collect-type {
      width: 100%;
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 16px;
      // padding: 0;
      .coll-tab {
        display: flex;
        justify-content: start;
        width: 100%;
        height: 54px;
        background: #ffffff;
        border-radius: 4px;
        padding-left: 32px;
        border-bottom: 1px #e4e6ec solid;
        li {
          line-height: 54px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-right: 64px;
          cursor: pointer;
        }
      }
      .coll-platform {
        width: 100%;
        border-bottom: 1px solid #e4e6ec;
        padding-left: 32px;
        margin-bottom: 16px;
        .card-row {
          display: flex;
          align-items: center;
          .card-box-desc {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #333333;
            float: right;
          }
          .card-box-flex {
            display: flex;
          }
          .card-box-alert {
            width: 572px;
            color: #ed731f;
            background: #fff5ee;
          }
        }
        .coll-platform-label {
          font-size: 14px;
          color: #999999;
          margin-right: 36px;
          float: left;
        }
        .coll-platform-checked {
          float: left;
          .platform-check-all {
            float: left;
            margin-right: 48px;
          }
          .platform-group {
            float: left;
            .platform-check-more {
              margin-right: 48px;
            }
          }
        }
      }
      .coll-screen {
        position: relative;
        display: flex;
        padding: 0 32px;
        padding-bottom: 15px;
        .screen-select-one {
          width: 110px;
          margin-right: 24px;
          ::v-deep {
            .el-input__prefix,
            .el-input__suffix {
              height: 36px;
            }
            .el-input.is-focus .el-input__inner {
              caret-color: #f58030;
              border: 1px solid #f58030 !important;
              transition: all 0.3s;
            }
          }
        }
        .screen-input {
          width: 240px;
          margin-right: 24px;
          ::v-deep {
            .el-input__inner {
              height: 36px;
            }
          }
        }
        .coll-screen-label {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 36px;
          margin-right: 12px;
        }
        .screen-select-tow {
          width: 190px;
          margin-right: 24px;
          ::v-deep {
            .el-input__inner {
              height: 36px;
            }

            .el-input__prefix,
            .el-input__suffix {
              height: 36px;
            }
            .el-input.is-focus .el-input__inner {
              caret-color: #f58030;
              border: 1px solid #f58030 !important;
              transition: all 0.3s;
            }
          }
        }
        .screen-date {
          height: 36px;
          width: 280px;
          margin-right: 32px;
        }
        .coll-screen-checkbox {
          line-height: 36px;
          margin-right: 24px;
        }
        .queryBtn {
          position: absolute;
          right: 108px;
          width: 60px;
          height: 36px;
          background-color: #f58030;
          color: white;
          line-height: 36px;
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
        }
        .resetBtn {
          position: absolute;
          right: 32px;
          width: 60px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border-radius: 4px;
          color: #000;
          border: 1px solid #e1e1e1;
          cursor: pointer;
        }
      }
    }
    .collect-info {
      .collect-info-box {
        display: flex;
        width: 100%;
        height: 207px;
        padding: 24px 12px;
        border-bottom: 1px solid #e4e6ec;
        .collect-info-box-left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 76px;
          height: 100%;
          margin-right: 40px;
          div {
            margin-bottom: 24px;
            border-radius: 1px;
            height: 28px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 28px;
            text-align: center;
          }
        }
        .collect-info-box-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: calc(100% - 116px);
          height: 100%;
          .box-right-title {
            position: relative;
            display: flex;
            justify-content: start;
            .tag {
              width: 58px;
              height: 20px;
              background: #ffedef;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ea3342;
              line-height: 20px;
              text-align: center;
              margin-top: 11px;
              margin-right: 8px;
            }
            .title-h3 {
              width: 882px;
              height: 40px;
              line-height: 40px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #333333;
              margin-right: 18px;
              cursor: pointer;
            }
            .original {
              width: auto;
              // margin-right: 335px;
              cursor: pointer;
              img {
                width: 14px;
                height: 16px;
                margin-right: 2px;
                vertical-align: middle;
              }
              div {
                display: inline-block;
                width: auto;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2e59ec;
                line-height: 38px;
                vertical-align: middle;
              }
            }
            .like {
              position: absolute;
              right: 0;
              width: 65px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2e59ec;
              line-height: 40px;
              cursor: pointer;
            }
          }
          .box-right-main {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
          .box-right-authors {
            position: relative;
            display: flex;
            justify-content: start;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            .authors1 {
              min-width: 98px;
              margin-right: 40px;
            }
            .authors2 {
              min-width: 126px;
              margin-right: 40px;
            }
            .authors3 {
              min-width: 185px;
              margin-right: 790px;
            }
            .authors4 {
              position: absolute;
              right: 12px;
              width: auto;
            }
          }
          .box-right-btn {
            display: flex;
            justify-content: end;
            img {
              width: 16px;
              height: 16px;
              vertical-align: middle;
              margin-right: 5px;
              margin-top: 11px;
            }
            div {
              display: inline-block;
              vertical-align: middle;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            .box-right-btn-btn {
              width: auto;
              display: flex;
              margin-left: 40px;
              cursor: pointer;
            }
            .btn1 {
              div {
                color: #f58030;
              }
            }
            .btn5 {
              width: 65px;
            }
          }
          div {
            width: 100%;
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
}
.selectedLi {
  color: #f58030 !important;
  border-bottom: 3px solid #f58030;
}
.collSelectedLi {
  color: #f58030 !important;
  border-bottom: 3px solid #f58030;
}
//表格斑马纹颜色
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #f6f7fa;
}
//下拉框样式
.report-select {
  width: 351px;
  ::v-deep {
    .el-input__inner {
      height: 36px;
    }

    .el-input__prefix,
    .el-input__suffix {
      height: 36px;
    }
    .el-input.is-focus .el-input__inner {
      caret-color: #f58030;
      border: 1px solid #f58030 !important;
      transition: all 0.3s;
    }
  }
}
// 表格操作栏中间的隔断
.span-cut-off {
  margin: 0 16px;
  color: #e4e6ec;
}
// 我的标签弹框padding
.edit-tag-dialog {
  padding: 11px 80px 20px;
}
</style>
