// 专项行动接口
import request from "../request.js";
// 获取数据列表
function getSpecialActionsList(data) {
  return request({
    url: "module-web/planSpecialStatistics/list",
    method: "post",
    data,
  });
}
// 新增专项行动数据
function addSpecialActions(data) {
  return request({
    url: "module-web/planSpecial/save",
    method: "post",
    data,
  });
}
// 专项行动关闭
function closeSpecialActions(data) {
  return request({
    url: "module-web/planSpecial/closeStatus/" + data,
    method: "get",
  });
}
// 获取已关闭话题列表
function getCloseSpecialActionsList(data) {
  return request({
    url: "module-web/planSpecialStatistics/closeList",
    method: "post",
    data,
  });
}
// 专项行动删除(关闭的话题)
function deleteSpecialActions(data) {
  return request({
    url: "module-web/planSpecial/delete/" + data,
    method: "get",
  });
}
// 专项行动开启(关闭的话题)
function startSpecialActions(data) {
  return request({
    url: "module-web/planSpecial/enableStatus/" + data,
    method: "get",
  });
}
// 修改专项行动
function editSpecialActions(data) {
  return request({
    url: "module-web/planSpecial/update",
    method: "post",
    data,
  });
}
// 获取详情事件概括
function getSpecialActionsGeneralization(data) {
  return request({
    url: "module-web/planSpecialStatistics/planSpecialDescription/" + data,
    method: "get",
  });
}
// 获取信息数量
function getSpecialActionsInformation(data) {
  return request({
    url: "module-web/planSpecialStatistics/informationCnt/" + data,
    method: "get",
  });
}
// 获取词云数据
function getWordsCloudData(data) {
  return request({
    url: "module-web/planSpecialStatistics/wordCloud",
    method: "post",
    data,
  });
}
// 修改词云
function setWordsCloudData(data) {
  return request({
    url: "module-web/planSpecialStatistics/updateHotWord",
    method: "post",
    data,
  });
}
// 获取详情页列表数据
function getDetailsDataList(data) {
  return request({
    url: "module-polyse/planSpecialData/list",
    method: "post",
    data,
  });
}
// 删除详情页数据
function deleteDetailsData(data) {
  return request({
    url: "module-polyse/planSpecialData/delete/" + data,
    method: "get",
  });
}
// 多选详情页数据
function deleteSelectDataIdList(data) {
  return request({
    url: "module-polyse/planSpecialData/deleteBatch",
    method: "post",
    data,
  });
}
// 获取详情中的详情
function getDetailsInDetails(data) {
  return request({
    url: "module-polyse/planSpecialData/info/" + data,
    method: "get",
  });
}
// 是否收藏
function setCollection(url, data) {
  return request({
    url: url + data,
    method: "get",
  });
}
// 获取发布趋势数据
function getReleasetrend(data) {
  return request({
    url: "module-web/planSpecialStatistics/publishTrend",
    method: "post",
    data,
  });
}
//信息数据列表导出
export function exportInfoList(data) {
  return request({
    url: "module-polyse/planSpecialData/exportExcel",
    method: "post",
    data,
  });
}
// 深度更新
export function deepUpdate(data) {
  return request({
    url: "module-web/planSpecial/depthUpdate/" + data,
    method: "get",
    data,
  });
}
// 生成报告
export function exportWordIng(data) {
  return request({
    url: "module-web/planSpecial/generateReport",
    method: "post",
    data,
  });
}

export default {
  getSpecialActionsList,
  addSpecialActions,
  closeSpecialActions,
  getCloseSpecialActionsList,
  deleteSpecialActions,
  startSpecialActions,
  editSpecialActions,
  getSpecialActionsGeneralization,
  getSpecialActionsInformation,
  getWordsCloudData,
  setWordsCloudData,
  getDetailsDataList,
  deleteDetailsData,
  deleteSelectDataIdList,
  getDetailsInDetails,
  setCollection,
  getReleasetrend,
  exportInfoList,
  deepUpdate,
  exportWordIng,
};
