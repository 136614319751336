// 属地报告接口
import request from "../request.js";

// 获取待审核数据数量--头部数据
export function list(data) {
  return request({
    url: '/module-system/planMisreExamine/list',
    method: 'post',
    data
  })
}
  // 根据id查询词库研判详情
  export function detail(id) {
    return request({
      url:  `/module-system/planMisreExamine/getById/${id}`,
      method: 'get',
    })
  }

  // 获取待审核数据数量--头部数据
export function save(data) {
  return request({
    url: '/module-system/planMisreExamine/save',
    method: 'post',
    data
  })
}