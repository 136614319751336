<template>
  <div class="pageBox">
    <!-- 筛选 -->
    <div class="platform-head">
      <div class="platform-type">
        <div class="platform-type-left">平台类型</div>
        <div class="platform-type-right">
          <el-checkbox
            class="check-all"
            v-model="myInfo.PlatformObj.checkAll"
            @change="CheckAllFn(1, $event)"
          >
            全选
          </el-checkbox>
          <el-checkbox-group
            class="checkbox-group"
            v-model="myInfo.PlatformObj.checkedform"
            @change="selectCheckAllFn(1, $event)"
          >
            <el-checkbox
              class="check-more"
              v-for="item in myInfo.PlatformObj.checkedData"
              :label="item"
              :key="item.value"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="platform-type">
        <div class="platform-type-left">主体类型</div>
        <div class="platform-type-right">
          <el-checkbox
            class="check-all"
            v-model="myInfo.subjectObj.checkAll"
            @change="CheckAllFn(2, $event)"
          >
            全选
          </el-checkbox>
          <el-checkbox-group
            class="checkbox-group"
            v-model="myInfo.subjectObj.checkedform"
            @change="selectCheckAllFn(2, $event)"
          >
            <el-checkbox
              class="check-more"
              v-for="item in myInfo.subjectObj.checkedData"
              :label="item"
              :key="item.value"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="platform-type">
        <div class="platform-type-left">功能</div>
        <div class="platform-type-right">
          <el-checkbox-group class="checkbox-group" v-model="myInfo.checkedAuthentication">
            <el-checkbox
              class="check-more"
              v-for="item in myInfo.authenticationType"
              :label="item"
              :key="item.value"
            >
              {{ item }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="fans-box">
        <el-select class="select" v-model="myInfo.fansSection" placeholder="请选择">
          <el-option
            v-for="item in myInfo.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          class="input"
          v-model="myInfo.condition"
          clearable
          placeholder="请输入"
        ></el-input>
        <div class="fans-num">信息类型</div>
        <el-select class="select-two" v-model="myInfo.infoType" clearable placeholder="请选择">
          <el-option
            v-for="item in myInfo.infoTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="fans-num">发布时间</div>
        <el-date-picker
          v-model="myInfo.publishTime"
          class="lelect-time"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        <div class="fans-num">处置情况</div>
        <el-select class="select-two" v-model="myInfo.management" clearable placeholder="请选择">
          <el-option
            v-for="item in myInfo.managementOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="search" @click="searchFn">查询</div>
        <div class="reset" @click="resetFn">重置</div>
      </div>
    </div>
    <div style="width: 100%; height: 16px; background: #f4f5fa"></div>
    <!-- 列表 -->
    <div class="data-list">
      <div class="data-box" v-for="(item, index) in queryList" :key="index">
        <div class="data-box-left">
          <el-popover
            placement="right-start"
            popper-class="monitor-yt-popover"
            effect="dark"
            width="400"
            trigger="hover"
          >
            <div class="popover">
              <div
                class="popover-type m-l-16 m-t-16"
                v-for="(value, inx) in item.xinxiTypeList"
                :key="inx"
              >
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <div slot="content">{{ value }}</div>
                  <div class="texthidden">{{ value }}</div>
                </el-tooltip>
              </div>
            </div>
            <div slot="reference">
              <div class="type" v-if="item.xinxiTypeList.length > 0">
                {{ item.xinxiTypeList[0] }}
              </div>
              <div class="type m-t-12" v-if="item.xinxiTypeList.length > 1">
                {{ item.xinxiTypeList[1] }}
              </div>
            </div>
          </el-popover>
          <div class="platform-type m-t-12">
            {{ getKey(item.platformType) }}
          </div>
        </div>
        <div class="data-box-right">
          <div class="name-box">
            <div class="title">
              <div class="status" v-if="item.warningFlag">已预警</div>
              <div class="name" @click="detail(item)" v-html="item.title"></div>
              <div class="bad-num" @click="viewOriginalText(item)">
                <img src="@/assets/img/wang/article.png" />
                查看原文
              </div>
            </div>
            <div class="read">
              <div class="similar">相似（{{ item.similarNum }}）</div>
            </div>
          </div>
          <div class="content">
            <img
              v-if="item.artificialFlag"
              src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202004%2F14%2F20200414210224_dnzpo.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1672910975&t=4f572bc1a2f09b5a21a599062a8883e0"
              alt=""
            />
            <div class="content-text">
              {{ item.content }}
            </div>
          </div>
          <div class="data-info">
            <div class="data-info-left">
              <div class="subject-type">平台类型：{{ item.platformName || "无" }}</div>
              <div class="subject-type">
                网站名称：{{ item.accountName || item.siteName || "无" }}
              </div>
              <div class="subject-type">作者：{{ item.author || "无" }}</div>
              <div class="subject-type">所在地区：{{ item.areaName || "无" }}</div>
              <div class="subject-type">发布时间：{{ item.publishTime || "无" }}</div>
            </div>
          </div>
          <div class="operation">
            <div class="operation-right">
              <div class="programme" @click="operation({ type: 1, item })" v-if="!item.collectFlag">
                <img src="@/assets/img/wang/collection.png" alt="" />
                <div>收藏</div>
              </div>
              <div class="programme" v-else @click="operation({ type: 6, item })">
                <img src="@/assets/img/zhang/yishoucang.png" alt="" />
                <div style="color: #ff9000">已收藏</div>
              </div>
              <div class="detail" @click="$main.copyFun(item.content)">
                <img src="@/assets/img/wang/content-info.png" alt="" />
                <div>复制原文</div>
              </div>
              <div class="detail" @click="$main.copyFun(item.sourceUrl)">
                <img src="@/assets/img/wang/copy-link.png" alt="" />
                <div>复制链接</div>
              </div>
              <div class="detail" @click="$main.copyFun(item.copyText)">
                <img src="@/assets/img/wang/copy-content.png" alt="" />
                <div>复制内容</div>
              </div>
            </div>
          </div>
        </div>
        <img
          v-if="item.disposalFlag"
          class="disposed"
          src="@/assets/img/wang/disposed.png"
          alt=""
        />
        <img v-else class="disposed" src="@/assets/img/wang/undisposed.png" alt="" />
      </div>
      <el-skeleton :rows="18" animated :loading="loading" />
      <Bitmap v-if="!loading && queryList.length == 0" />
      <Pagination
        :pageNumber="page.pageNumber"
        :pageSize="page.pageSize"
        :total="total"
        @SizeChange="SizeChange"
        @CurrentChange="CurrentChange"
      ></Pagination>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="operation"></deleteDialog>
  </div>
</template>

<script>
import deleteDialog from "@/components/deleteDialog.vue";
import Pagination from "@/components/pagination.vue";
import { getlist, update } from "@/api/harmful/index";
import { Dictionaries } from "@/api/platformaccount/index";
import Bitmap from "@/components/bitmap.vue";
export default {
  components: {
    Pagination,
    deleteDialog,
    Bitmap,
  },
  data() {
    return {
      myInfo: {
        // 平台类型
        PlatformObj: {
          checkAll: false, // 平台类型全选
          checkedform: [], //平台类型已选
          checkedData: [], //平台类型 可选数据
          isIndeterminate: false, //平台类型不确定状态
        },
        // 主体类型
        subjectObj: {
          checkAll: false, // 主体类型全选
          checkedform: [], //主体类型已选
          checkedData: [], //主体类型
          isIndeterminate: false, //主体类型不确定状态
        },
        checkedAuthentication: [], //已选认证类型
        authenticationType: ["已预警", "隐藏重复数据"], //功能
        condition: "", //搜索条件
        fansSection: 1, //粉丝数量区间
        options: [
          { label: "文章标题", value: 1 },
          { label: "文章内容", value: 2 },
          { label: "信息来源", value: 3 },
        ],
        infoType: null, //信息类型
        infoTypeOptions: [], //信息类型
        publishTime: [], //发布时间
        management: "", //处置情况
        managementOptions: [
          { label: "已处置", value: 1 },
          { label: "未处置", value: 2 },
        ], //处置情况
      },
      queryList: [], // 列表
      page: {
        page: 1,
        limit: 10,
      },
      total: 0,
      loading: false,
    };
  },
  created() {
    this.getDictionariesList(); // 字典 请求
    this.getList({
      isCollect: true,
    }); //列表
  },
  methods: {
    // 字典 请求
    getDictionariesList() {
      const todata = (data) => data.map((item) => ({ label: item.label, value: item.value }));
      Dictionaries("platform_type").then((Res) => {
        this.myInfo.PlatformObj.checkedData = todata(Res.data.data);
      });
      Dictionaries("body_type").then((Res) => {
        this.myInfo.subjectObj.checkedData = todata(Res.data.data);
      });
      Dictionaries("message_type").then((Res) => {
        this.myInfo.infoTypeOptions = todata(Res.data.data);
      });
    },
    // 查看原文
    viewOriginalText(item) {
      window.open(item.sourceUrl);
    },
    // 获取列表
    async getList(obj) {
      this.loading = true;
      this.queryList = [];
      const { page } = this;
      const Res = await getlist(obj ? { ...obj, ...page } : page);
      if (Res.data.code == 200) {
        const {
          data: { list, totalCount },
        } = this.$main.getResData(Res);
        list.forEach((v) => {
          v.xinxiTypeList =
          v.artificialMessageTypesName &&  v.artificialMessageTypesName.length > 0
              ? v.artificialMessageTypesName.split(",")
              :v.machineMessageTypesName && v.machineMessageTypesName.length > 0
              ? v.machineMessageTypesName.split(",")
              : [];
        });
        this.queryList = list;
        this.total = Number(totalCount);
        this.loading = false;
      } else {
        this.queryList = [];
        this.loading = false;
      }
    },
    // 查询
    searchFn() {
      const {
        PlatformObj,
        subjectObj,
        publishTime,
        infoType,
        management,
        checkedAuthentication,
        fansSection,
        condition,
      } = this.myInfo;
      const obj = {
        bodyTypes: subjectObj.checkedform.map((v) => v.value).join(","),
        platformTypes: PlatformObj.checkedform.map((v) => v.value).join(","),
        publishTimeStart: publishTime?publishTime[0] : "",
        publishTimeEnd: publishTime?publishTime[1] : "",
        artificialMessageTypes: infoType || "",
        isCollect: true,
      };
      if (management) obj.isDisposal = management == 1 ? true : false;
      if (checkedAuthentication.length > 0) {
        checkedAuthentication.forEach((v) => {
          if (v == "已预警") {
            obj.isWarning = true;
          } else if (v == "隐藏重复数据") {
            obj.isduplicate = true;
          }
        });
      }
      if (fansSection == 1 && condition.length > 0) obj.title = condition;
      if (fansSection == 2 && condition.length > 0) obj.content = condition;
      if (fansSection == 3 && condition.length > 0) obj.source = condition;
      this.getList(obj);
    },
    // 重置
    resetFn() {
      this.myInfo.checkedAuthentication = [];
      this.myInfo.management = "";
      this.myInfo.infoType = null;
      this.myInfo.publishTime = [];
      this.myInfo.fansSection = 1;
      this.myInfo.condition = "";
      this.myInfo.PlatformObj.checkedform = [];
      this.myInfo.PlatformObj.checkAll = false;
      this.myInfo.PlatformObj.isIndeterminate = false;
      this.myInfo.subjectObj.checkedform = [];
      this.myInfo.subjectObj.checkAll = false;
      this.myInfo.subjectObj.isIndeterminate = false;
      this.searchFn();
    },
    // 全选按钮
    CheckAllFn(idx, val) {
      // idx：类型  val：true为全选 false为非全选
      // 对象的key和idx匹配 就使用对应的值  值为数组 最大是两位 第二位留给backfn
      this.$main.selectAll(
        val,
        ...{
          1: [this.myInfo.PlatformObj], // 平台类型
          2: [this.myInfo.subjectObj], // 主体类型
          3: [this.myInfo.authenticationObj], // 认证情况
        }[idx]
      );
    },
    // 多选按钮
    selectCheckAllFn(idx, { length }) {
      // idx：类型   length：已选数组的长度
      // 对象的key和idx匹配 就使用对应的值  值为数组 最大是两位 第二位留给backfn
      this.$main.selections(
        length,
        ...{
          1: [this.myInfo.PlatformObj], // 平台类型
          2: [this.myInfo.subjectObj], // 主体类型
          3: [this.myInfo.authenticationObj], // 认证情况
        }[idx]
      );
    },
    SizeChange(val) {
      this.limit = val;
      this.searchFn();
    },
    CurrentChange(val) {
      this.page.page = val;
      this.searchFn();
    },
    //详情
    detail(item) {
      const { id } = item;
      this.operation({ type: 3, item });
      let routeUrl = this.$router.resolve({
        path: "/settings/harmfulDetail",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: id,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    deleteFnMy(item) {
      item.message = "这篇新闻";
      this.$refs.openDialog.openDialog(item);
    },
    async operation({ type, item }) {
      const { id } = item;
      const Res = await update({
        dataIds: id,
        operationType: type,
      });
      const { code } = Res.data;
      if (code == 200) {
        let typeText = "";
        if (type == 1) {
          typeText = "已收藏";
          item.collectFlag = true;
        } else if (type == 6) {
          typeText = "取消收藏";
          item.collectFlag = false;
        } else if (type == 2) {
          typeText = "已成功删除";
          setTimeout(() => {
            this.searchFn();
          }, 100);
        }
        if (type == 3) return;
        this.$message({
          message: `${typeText ? typeText : "操作成功"}`,
          type: "success",
        });
      } else {
        if (type == 3) return;
        this.$message.error("操作失败!");
      }
    },
    // 通过key获取平台类型字典的值
    getKey(key) {
      let label = "";
      this.myInfo.PlatformObj.checkedData.forEach((v) => {
        if (v.value == key) {
          label = v.label;
        }
      });
      return label;
    },
  },
};
</script>

<style>
/* //最外层div,修改背景色，边框 */
.el-popover.monitor-yt-popover {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 0 16px 0;
  /* border-color: #146ebd; */
}
/* //修改title颜色 */
.monitor-yt-popover .el-popover__title {
  color: white;
}
/* //修改下面的小三角，属性名根据组件的placement位置做相应修改 */
.monitor-yt-popover .popper__arrow::after {
  border-right-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
<style lang="scss" scoped>
.pageBox {
  display: flex;
  flex-direction: column;
  .account {
    width: 120px;
    height: 37px;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 37px;
    margin-bottom: 24px;
  }
  .platform-head {
    display: flex;
    flex-direction: column;
    padding: 0px 35px;
    height: auto;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    .platform-type {
      display: flex;
      min-height: 52px;
      width: 100%;
      height: auto;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      border-bottom: 1px solid #e4e6ec;
      .platform-type-left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 16px;
        width: 96px;
        height: 100%;
      }
      .platform-type-right {
        display: flex;
        width: calc(100% - 96px);
        height: 100%;
        .check-all {
          //全选
          margin: 16px 48px 0px 0px;
          ::v-deep .el-checkbox__label {
            color: #333333;
          }
          ::v-deep .el-checkbox__inner {
            border-color: #e4e6ec;
          }
          ::v-deep .el-checkbox__input.is-checked {
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
          ::v-deep .el-checkbox__input.is-indeterminate {
            //半选
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
        }
        .checkbox-group {
          width: calc(100% - 100px);
          height: 100%;
          padding-bottom: 16px;
          .check-more {
            //多选
            margin: 16px 48px 0px 0px;
            ::v-deep .el-checkbox__label {
              color: #333333;
            }
            ::v-deep .el-checkbox__inner {
              border-color: #e4e6ec;
            }
          }
          ::v-deep .el-checkbox.check-more.is-checked {
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
        }
        ::v-deep .el-radio {
          margin-top: 18px;
          margin-right: 34px;
          .el-radio__label {
            color: #333333;
          }
          .el-radio__inner {
            border-color: #e4e6ec;
          }
          .el-radio__input.is-checked {
            .el-radio__inner {
              background-color: #f58030;
            }
          }
        }
      }
    }
    .fans-box {
      display: flex;
      align-items: center;
      height: 68px;
      width: 100%;
      .fans-num {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 12px;
        margin-left: 24px;
        white-space: nowrap;
      }
      .input {
        //输入框
        width: 160px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 160px;
          border-radius: 4px;
        }
        ::v-deep .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .select {
        //下拉选择
        width: 120px;
        height: 36px;
        margin: 0px 24px 0 0px;
        ::v-deep .el-input__inner {
          width: 120px;
          height: 36px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .select-two {
        width: 160px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 160px;
          height: 36px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      ::v-deep
        .el-date-editor.el-range-editor.el-input__inner.lelect-time.el-date-editor--daterange {
        height: 36px;
        width: 280px;
        border-radius: 4px;
        .el-range-input {
          font-size: 13px;
          width: 100px;
        }
        .el-range-input:nth-child(4) {
          margin-left: 10px;
        }
        .el-input__icon.el-range__icon.el-icon-date {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .el-input__icon.el-range__close-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .el-range-separator {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
        }
      }
      .search {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        width: 60px;
        height: 32px;
        background: #f58030;
        border-radius: 4px;
        margin-right: 16px;
        cursor: pointer;
        margin-left: 120px;
      }
      .reset {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        cursor: pointer;
      }
    }
    .screen-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;
      .select-type {
        width: 80px;
        height: 36px;
        margin-right: 24px;
        ::v-deep .el-input__inner {
          width: 80px;
          height: 36px;
          border-radius: 4px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .input-content {
        width: 240px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 240px;
          border-radius: 4px;
        }
        ::v-deep .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .address {
        font-size: 14px;
        white-space: nowrap;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 0 12px 0 24px;
      }
      .select-address {
        width: 190px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 190px;
          height: 36px;
          border-radius: 4px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .query {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        background: #f58030;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 70px;
        cursor: pointer;
      }
      .data-import {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 97px;
        height: 36px;
        background: #fff1e8;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ed731f;
        margin-left: 16px;
        cursor: pointer;
        img {
          height: 14px;
          width: 14px;
          margin-right: 2px;
        }
      }
      .reset {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
  .account-number {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    width: 100%;
    height: 68px;
    .add-account {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 128px;
      height: 36px;
      background: #f58030;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      img {
        height: 12px;
        width: 12px;
        margin-right: 2px;
      }
    }
  }
  .data-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 4px;
    padding: 15px 35px;
    .data-box {
      display: flex;
      // align-items: center;
      width: 100%;
      height: auto;
      padding: 24px 0px;
      border-bottom: 1px solid #e4e6ec;
      .disposed {
        height: 114px;
        width: 129px;
        position: absolute;
        right: 138px;
      }
      .data-box-left {
        width: 132px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;

        .type {
          cursor: pointer;
          width: 114px;
          min-height: 28px;
          text-align: center;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          padding: 4px 10px;
          background: #ff4b1f;
          border-radius: 1px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .platform-type {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 28px;
          background: #f58030;
          width: 114px;
          padding: 4px 10px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          // margin-top: 24px;
        }
      }
      .data-box-right {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 132px);
        height: 100%;
        .name-box {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          width: 100%;
          height: 25px;
          margin-bottom: 16px;

          .title {
            display: flex;
            width: 80%;
            height: 25px;
            .status {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              background: #ffedef;
              padding: 0 8px;
              margin-right: 16px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ea3342;
            }
            .name {
              height: 25px;
              max-width: calc(100% - 160px);
              width: auto;
              line-height: 25px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
              color: #333333;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .bad-num {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2e59ec;
              margin-left: 18px;
              cursor: pointer;
              img {
                height: 16px;
                width: 18px;
                margin-right: 8px;
              }
            }
          }
          .read {
            display: flex;
            justify-content: flex-end;
            width: 20%;
            .similar {
              display: flex;
              align-items: center;
              width: auto;
              height: 25px;
              margin-right: 8px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
            .unread {
              display: flex;
              align-items: center;
              width: auto;
              height: 25px;
              img {
                width: 17px;
                height: 12px;
                margin-right: 8px;
              }
              span {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ea3342;
              }
            }
          }
        }
        .content {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 16px;
          img {
            height: 52px;
            width: 52px;
            margin-right: 13px;
            border-radius: 50%;
          }
          .content-text {
            width: calc(100% - 65px);
            max-height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .data-info {
          display: flex;
          height: 20px;
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 16px;
          .data-info-left {
            display: flex;
            align-items: center;
            width: 80%;
            height: 20px;
            .subject-type {
              margin-right: 46px;
            }
          }
          .data-info-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 20px;
            width: 20%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          .status {
            color: #02bc7c;
            margin-right: 46px;
          }
          .region {
            max-width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 46px;
          }

          .company {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 46px;
          }
          .fnas {
            margin-right: 46px;
          }
        }
        .operation {
          display: flex;
          height: 20px;
          width: 100%;
          .operation-left {
            display: flex;
            align-items: center;
            width: 20%;
            height: 100%;
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-right: 8px;
            }
          }
          .operation-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            .detail {
              display: flex;
              height: 100%;
              width: auto;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              cursor: pointer;
              margin-right: 40px;
              img {
                height: 14px;
                width: 13px;
                margin-right: 7px;
              }
            }
          }
          .programme {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            margin-right: 40px;
            img {
              height: 14px;
              width: 15px;
              margin-right: 7px;
            }
          }
          .remove {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 15px;
              width: 15px;
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}

.popover {
  // width: 300px;
  // height: 300px;
  // background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content:space-evenly
  .popover-type {
    cursor: pointer;
    width: 114px;
    min-height: 28px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 4px 10px;
    background: #ff4b1f;
    border-radius: 1px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
