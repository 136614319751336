// 系统设置接口
import request from "../request.js";
import  loginMenu from '../loginMenu'
// 获取用户信息
function getUserInformation() {
  return request({
    url: "module-system/SysPlanSpecial/loginUser",
    method: "get",
  });
}
// 获取机构信息
function getMechanismInformation(data) {
  return loginMenu({
    // url: "http://api.admin.konne.cn/sdbackend/apanageOrg/info/" + data,
    url: "sdbackend/apanageOrg/info/" + data,
    method: "get",
  });
}
// 获取机构管理员
function getMechanismAdmList(data) {
  return loginMenu({
    // url: "http://api.admin.konne.cn/sdbackend/apanageOrg/adminList",
    url: "sdbackend/apanageOrg/adminList",
    method: "post",
    data,
  });
}
// 获取机构预警规则
function getMechanismWarningRules() {
  return request({
    url: "module-system/orgWarnRule/orgWarnRuleList",
    method: "get",
  });
}
// 获取监测方案预警事件选项
function getDetectionSchemeSelect() {
  return request({
    url: "module-web/webPlanMonitor/getPlanMonitorByOrgId",
    method: "post",
    data: {},
  });
}
// 预警设置保存
function saveAlertRules(data) {
  return request({
    url: "module-system/orgWarnRule/update",
    method: "post",
    data,
  });
}
// 获取登录日志
function getLoginLogList(data) {
  return loginMenu({
    // url: "http://api.admin.konne.cn/log/sysClientsLog/loginList",
    url: "log/sysClientsLog/loginList",
    method: "post",
    data,
  });
}
// 获取操作类型
function getWorkTypeList() {
  return loginMenu({
    // url: "http://api.admin.konne.cn/log/sysClientsLog/getSysType",
    url: "log/sysClientsLog/getSysType",
    method: "get",
  }); 
}
// 获取操作日志
function getWorkList(data) {
  return loginMenu({
    // url: "http://api.admin.konne.cn/log/sysClientsLog/workList",
    url: "log/sysClientsLog/workList",
    method: "post",
    data,
  });
}
// 获取我的标签
function getMyTagsList(data) {
  return request({
    url: "module-system/orgLabel/list",
    method: "post",
    data,
  });
}
// 删除我的标签
function myTagDelete(data) {
  return request({
    url: "module-system/orgLabel/delete/" + data,
    method: "get",
  });
}
// 修改我的标签
function myTagEdit(data) {
  return request({
    url: "module-system/orgLabel/update",
    method: "post",
    data,
  });
}
// 新增我的标签
function myTagAdd(data) {
  return request({
    url: "module-system/orgLabel/save",
    method: "post",
    data,
  });
}
// 获取报告生成规则
function getReportGenerationRules() {
  return request({
    url: "module-system/reportGenerationRule/userInfo",
    method: "get",
  });
}
// 获取报告生成规则
function editReportGenerationRules(data) {
  return request({
    url: "module-system/reportGenerationRule/webUpdate",
    method: "post",
    data,
  });
}
// 登录日志导出
function reportLoginData(data) {
  return request({
    url: "module-web/sysLog/loginLogExportExcel",
    method: "post",
    data,
  });
}
// 操作日志导出
function reportWorkData(data) {
  return request({
    url: "module-web/sysLog/workLogExportExcel",
    method: "post",
    data,
  });
}
// 错误表述账号
function getCwAccount(data) {
  return request({
    url: "module-system/planMisre/getOrgAccountMisreVos",
    method: "post",
    data,
  });
}
// 修改密码
function updatePassWord(data) {
  return request({
    url: "http://api.admin.konne.cn/sdbackend/cancelCheck/updatePassWord",
    method: "post",
    data,
  });
}
//  错误表述账号
export function queryMisreAccountPage(data) {
  return request({
    url: "/module-account/account/queryMisreAccountPage",
    method: 'post',
    data
  })
}
// 批量添加不预警账号
export function batchSave(data) {
  return request({
    url: "/module-account/account/batchSave",
    method: 'post',
    data
  })
}
// 批量关闭不预警账号
export function batchDelete(data) {
  return request({
    url: "/module-account/account/batchDelete",
    method: 'post',
    data
  })
}

// 查询党政类型接口
export function importantBodyType(data) {
  return request({
    url: "/module-system/sysDictData/info/importantBodyType",
    method: 'get',
  })
}
export default {
  getUserInformation,
  getMechanismInformation,
  getMechanismAdmList,
  getMechanismWarningRules,
  getDetectionSchemeSelect,
  saveAlertRules,
  getLoginLogList,
  getWorkList,
  getWorkTypeList,
  getMyTagsList,
  myTagDelete,
  myTagEdit,
  myTagAdd,
  getReportGenerationRules,
  editReportGenerationRules,
  reportLoginData,
  reportWorkData,
  getCwAccount,
  updatePassWord
};
