// 有害信息
import request from '../request.js';
// 获取列表
export function getlist(data) {
    return request({
        url:"module-listener-complex/harmfulData/list",
        method:"post",
        data
    })
}
// 获取相似数量
export function similarTitle(data) {
    return request({
        url:"module-listener-complex/harmfulData/similarTitle",
        method:"post",
        data 
    })
}
// 详情
export function detail(detail) {
    return request({
        url:`module-listener-complex/harmfulData/detail/${detail}`,
        method:"get"
    })
}
// 修改有害信息(已-未读。删除。收藏)
export function update(data) {
    return request({
        url:"module-listener-complex/harmfulData/update",
        method:"post",
        data
    })
}
// 导出
export function exportExcel(data) {
    return request({
        url:'module-listener-complex/harmfulData/exportExcel',
        method:'post',
        data,
        responseType: "blob",
    })
}
//获取处置记录
export function disposeRecord(dataId) {
    return request({
        url: `module-listener-complex/harmfulDisposeRecord/queryByDataId/${dataId}`,
        method: "get"
    })
}

//更新处置记录
export function updateDispose(data) {
    return request({
        url: `module-listener-complex/harmfulDisposeRecord/update`,
        method: "put",
        data
    })
}