<template>
  <div>
    <div class="head flex-b m-b-16">
      <div class="search flex-c">
        <div class="search-label">账号名称</div>
        <el-input v-model="name" placeholder="请输入" size="medium" clearable></el-input>
        <div class="search-label">平台类型</div>
        <el-select v-model="type" placeholder="请选择" clearable>
          <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="search-label">主体类型</div>
        <el-select v-model="bodyType" placeholder="请选择" clearable>
          <el-option v-for="item in bodyList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
          <div class="search-label">预警状态</div>
          <el-checkbox   style="margin-left:10px" v-model="isNoWarning">不预警账号</el-checkbox>
             <!-- <el-switch
           
  v-model="isNoWarning"
  active-color="#13ce66"
  inactive-color="#ff4949">
</el-switch> -->
        <!-- <el-select v-model="type" placeholder="请选择" clearable>
          <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
      </div>
      <div class="query flex-b">
        <div class="queryBtn" @click="query">查询</div>
        <div class="resetBtn" @click="reset">重置</div>
        <el-dropdown>
  <el-button type="primary">
    批量操作<i class="el-icon-arrow-down el-icon--right"></i>
  </el-button>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item @click.native="piLiangAll(true)">批量开启预警</el-dropdown-item>
    <el-dropdown-item @click.native="piLiangAll(false)">批量关闭预警</el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>
      </div>
    </div>
    <el-card shadow="never">
      <el-table v-loading="logLoading" :data="loginData" stripe :header-cell-style="{
            'text-align': 'center',
            background: '#F6F7FA',
            color: '#333',
          }" 
          :cell-style="{ 'text-align': 'center' }" style="
            width: 100%;
            text-align: center;
            border: 1px solid #e4e6ec;
            border-bottom: none;
          "
          @selection-change="handleSelectionChange"
          >
        <!-- :row-class-name="tableLogClassName" -->
        <!-- <el-table-column label="账号名称" show-overflow-tooltip>
          <template slot-scope="scoped">
            <span @click="goDetail(scoped.row)" class="cur" style="color:#2e59ec">{{scoped.row.accountName}}</span>
          </template>
        </el-table-column> -->
         <el-table-column
      type="selection"
      width="55"> </el-table-column>
         <el-table-column prop="accountName" label="账号名称" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="platformTypeName" label="平台类型" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="bodyTypeName" label="主体类型" show-overflow-tooltip> </el-table-column>
          <el-table-column label="主页链接" show-overflow-tooltip>
          <template slot-scope="scoped">
            <span @click="goNew(scoped.row.homeUrl)" class="cur" style="color:#2e59ec">{{scoped.row.homeUrl}}</span>
          </template>
        </el-table-column>
           <el-table-column label="预警状态开关" show-overflow-tooltip>
          <template slot-scope="scoped">
            <el-switch
            @change="switch_fn(scoped.row)"
  v-model="scoped.row.warningStatus"
  active-color="#13ce66"
  inactive-color="#ff4949">
</el-switch>

          </template>
        </el-table-column>
      </el-table>
      <Pagination :pageNumber="page.pageNumber" :pageSize="page.pageSize" :total="total" @SizeChange="SizeChange" @CurrentChange="CurrentChange"></Pagination>
    </el-card>
  </div>
</template>

<script>
import Api from "@/api/setUp/index";
import {queryMisreAccountPage,batchSave,batchDelete,importantBodyType} from '@/api/setUp/index'
import Pagination from "@/components/pagination.vue";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      name: '',
      type: '',
      bodyType:'',
      bodyList:[],
      typeList: [
      ],
      loginData: [
      ],
      copyloginData:[],
      page: {
        pageNumber: 1,
        pageSize: 10
      },
      total: 0,
      logLoading: false,
      isNoWarning:false,
    }
  },
  created() {
    this.importantBodyType()

    // this.niubi()
  },
  mounted() {
    this.typeList = JSON.parse(localStorage.getItem('platform_type'))
    this.getList()
  },
  methods: {
    getData() {
     return [
    {
        "eventId": 26,
        "id": 26,
        "name": "公安管辖",
        "parentId": 0,
        "pathId": "26",
        "pathName": "公安管辖",
        "keywordSet": "公安 公安分局 督察 警察 防爆警 民警 协警 特警 刑警 警员 交警 公安局 派出所 武警 片警 辅警 户籍警 交巡警 网警 法警 警方 巡防员 监狱 狱警 出警 国保 老警 条子 警长 警校 网监 便衣 警队 巡防队员 治安队 治安员 海警 女警 警督 110 干警 巡警 乘警  消防警 防暴警 警官 便衣 警车 拘留所 看守所 专案组 边防 协勤 网络大队 网安 警察局  军警 警械 拘留 破案 治警 执法者 报警 办案",
        "ignoringKeyword": null,
        "excludeKey": null,
        "isSetKeyword": null,
        "updateTime": null,
        "list": [
            {
                "eventId": 27,
                "id": 27,
                "name": "刑事",
                "parentId": 26,
                "pathId": "26-27",
                "pathName": "公安管辖-刑事",
                "keywordSet": "未出警 不出警 拒不立案 报警多次 多次出警 刑事案件",
                "ignoringKeyword": null,
                "excludeKey": null,
                "isSetKeyword": null,
                "updateTime": null,
                "list": [
                    {
                        "eventId": 28,
                        "id": 28,
                        "name": "绑架",
                        "parentId": 27,
                        "pathId": "26-27-28",
                        "pathName": "公安管辖-刑事-绑架",
                        "keywordSet": "被绑 遭绑 赎金 掳走 绑架",
                        "ignoringKeyword": null,
                        "excludeKey": null,
                        "isSetKeyword": null,
                        "updateTime": null,
                        "list": null,
                        "eventJson": null,
                        "keyWordCount": null,
                        "eventLevel": 3
                    },
                    {
                        "eventId": 29,
                        "id": 29,
                        "name": "盗窃",
                        "parentId": 27,
                        "pathId": "26-27-29",
                        "pathName": "公安管辖-刑事-盗窃",
                        "keywordSet": "盗窃 关盗窃",
                        "ignoringKeyword": "忽略关键字 忽略关键字 忽略关键字 忽略关键字",
                        "excludeKey": "排除关键字 排除关键字 排除关键字 排除关键字",
                        "isSetKeyword": "1",
                        "updateTime": null,
                        "list": null,
                        "eventJson": null,
                        "keyWordCount": null,
                        "eventLevel": 3
                    },
                    {
                        "eventId": 33,
                        "id": 33,
                        "name": "杀人",
                        "parentId": 27,
                        "pathId": "26-27-33",
                        "pathName": "公安管辖-刑事-杀人",
                        "keywordSet": "盗窃 关盗窃",
                        "ignoringKeyword": "忽略关键字 忽略关键字 忽略关键字 忽略关键字",
                        "excludeKey": "排除关键字 排除关键字 排除关键字 排除关键字",
                        "isSetKeyword": "1",
                        "updateTime": null,
                        "list": null,
                        "eventJson": null,
                        "keyWordCount": null,
                        "eventLevel": 3
                    },
                    {
                        "eventId": 34,
                        "id": 34,
                        "name": "拐卖",
                        "parentId": 27,
                        "pathId": "26-27-34",
                        "pathName": "公安管辖-刑事-拐卖",
                        "keywordSet": "关键字设置 关键字设置 关键字设置 关键字设置",
                        "ignoringKeyword": "忽略关键字 忽略关键字 忽略关键字 忽略关键字",
                        "excludeKey": "排除关键字 排除关键字 排除关键字 排除关键字",
                        "isSetKeyword": "1",
                        "updateTime": null,
                        "list": null,
                        "eventJson": null,
                        "keyWordCount": null,
                        "eventLevel": 3
                    },
                    {
                        "eventId": 35,
                        "id": 35,
                        "name": "放火",
                        "parentId": 27,
                        "pathId": "26-27-35",
                        "pathName": "公安管辖-刑事-放火",
                        "keywordSet": "关键字设置 关键字设置 关键字设置 关键字设置",
                        "ignoringKeyword": "忽略关键字 忽略关键字 忽略关键字 忽略关键字",
                        "excludeKey": "排除关键字 排除关键字 排除关键字 排除关键字",
                        "isSetKeyword": "1",
                        "updateTime": null,
                        "list": null,
                        "eventJson": null,
                        "keyWordCount": null,
                        "eventLevel": 3
                    }
                ],
                "eventJson": null,
                "keyWordCount": null,
                "eventLevel": 2
            }
        ],
        "eventJson": null,
        "keyWordCount": null,
        "eventLevel": 1,
        filterList:[],
        renTree:[]
    }
]
    },
    filterData() {
        return          [
                {
                        "eventId": 28,
                        "id": 28,
                        "name": "绑架",
                        "parentId": 27,
                        "pathId": "26-27-28",
                        "pathName": "公安管辖-刑事-绑架",
                        "keywordSet": "被绑 遭绑 赎金 掳走 绑架",
                        "ignoringKeyword": null,
                        "excludeKey": null,
                        "isSetKeyword": null,
                        "updateTime": null,
                        "list": null,
                        "eventJson": null,
                        "keyWordCount": null,
                        "eventLevel": 3
                    },
                    {
                        "eventId": 29,
                        "id": 29,
                        "name": "盗窃",
                        "parentId": 27,
                        "pathId": "26-27-29",
                        "pathName": "公安管辖-刑事-盗窃",
                        "keywordSet": "盗窃 关盗窃",
                        "ignoringKeyword": "忽略关键字 忽略关键字 忽略关键字 忽略关键字",
                        "excludeKey": "排除关键字 排除关键字 排除关键字 排除关键字",
                        "isSetKeyword": "1",
                        "updateTime": null,
                        "list": null,
                        "eventJson": null,
                        "keyWordCount": null,
                        "eventLevel": 3
                    },
                    {
                        "eventId": 33,
                        "id": 33,
                        "name": "杀人",
                        "parentId": 27,
                        "pathId": "26-27-33",
                        "pathName": "公安管辖-刑事-杀人",
                        "keywordSet": "盗窃 关盗窃",
                        "ignoringKeyword": "忽略关键字 忽略关键字 忽略关键字 忽略关键字",
                        "excludeKey": "排除关键字 排除关键字 排除关键字 排除关键字",
                        "isSetKeyword": "1",
                        "updateTime": null,
                        "list": null,
                        "eventJson": null,
                        "keyWordCount": null,
                        "eventLevel": 3
                    },
                    {
                        "eventId": 34,
                        "id": 34,
                        "name": "拐卖",
                        "parentId": 27,
                        "pathId": "26-27-34",
                        "pathName": "公安管辖-刑事-拐卖",
                        "keywordSet": "关键字设置 关键字设置 关键字设置 关键字设置",
                        "ignoringKeyword": "忽略关键字 忽略关键字 忽略关键字 忽略关键字",
                        "excludeKey": "排除关键字 排除关键字 排除关键字 排除关键字",
                        "isSetKeyword": "1",
                        "updateTime": null,
                        "list": null,
                        "eventJson": null,
                        "keyWordCount": null,
                        "eventLevel": 3
                    },
        ]
    },
   async niubi() {
      this.renTree = this.getData()
       this.filterList = this.filterData()
      // console.log(renTree)
      console.log('选中的',this.filterList) 
      console.log( '处理前------',this.renTree)
      await this.startNiubi(this.renTree)
      console.log('处理后-----',this.renTree)
    },
    startNiubi(Tree) {
        // const first =  Tree.find(item => item.eventLevel ==1 ) // 判断是不是第一层数据
        Tree.forEach(obj => {
            // if(!first)  
            obj.list = this.filterList.filter(v=> v.parentId === obj.eventId)  
            if(obj?.list && obj?.list.length > 0) this.startNiubi(obj.list,obj)
        })
    },
    async importantBodyType() {
      const Res = await importantBodyType()
      this.bodyList = Res.data.data || []
    },
    handleSelectionChange(val) {
      this.copyloginData = val
    },
    getParam(row) {
        const {warningStatus,accountId,platformType} = row;
        const param = !warningStatus ? row: {accountId,platformType};
        return param
    },
    piLiangAll(Type) {
      if(this.copyloginData.length ==0) return this.$message.warning("请至少勾选一条数据！")
      this.copyloginData.forEach(v=> {
          this.loginData.forEach(item=> {
            if(v.accountId == item.accountId) 
               item.warningStatus = Type
          })
      })
      this.switch_fn({
        warningStatus:Type,
        Params:this.copyloginData.map(row=>this.getParam(row)) ||[]
      })
    },
    async switch_fn(row) {
        const {warningStatus,Params} = row;
        const APiFn = !warningStatus ? batchSave: batchDelete;
        const messageFN = !warningStatus ? this.$message.warning: this.$message.success;
        const accounts = Params && Params.length>0 ? Params: [this.getParam(row)]
        const Res = await APiFn({accounts})
        if(Res.data.code ==200) messageFN(Res.data.data)
    },
    async getList() {
      this.logLoading = true
      let data = {
        pageNo: this.page.pageNumber,
        pageSize: this.page.pageSize,
      }
      if(this.isNoWarning) data.isNoWarning = this.isNoWarning
      if(this.name) data.accountName = this.name;
      if(this.bodyType) data.bodyType = this.bodyType;
      if(this.type) data.platformType = this.type;
      let res = await queryMisreAccountPage(data)
      if (res.data.code == 200) {
        this.logLoading = false;
        this.loginData = res?.data?.data?.list||[]
        this.total = res.data.data.totalCount * 1
      } else {
        this.logLoading = false
        this.$message.error('出错了')
      }
    },
    goNew(url) {
       window.open(url, "_blank");
    },
    goDetail(row){
      this.$main.routeResolve('/accountDetail',row)
    },
    query() {
      this.page.pageNumber = 1
      this.getList()
    },
    reset() {
      this.name = ''
      this.type = ''
      this.bodyType = ''
      this.type = ''
      this.page.pageNumber = 1;
      this.isNoWarning =false;
      this.getList()
    },
    SizeChange(val) {
      this.page.pageSize = val
      this.page.pageNumber = 1
      this.getList()
    },
    CurrentChange(val) {
      this.page.pageNumber = val
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}
.search {
  .search-label {
    font-weight: 600;
    font-size: 14px;
  }
  .el-input {
    width: 190px;
    margin-left: 12px;
    margin-right: 24px;
  }
  .el-select {
    width: 190px;
    margin-left: 12px;
    margin-right: 24px;
  }
}
.query {
  // width: 155px;
}
</style>