// 错误表述接口
import request from "../request.js";
// 获取数据列表
function getErrorExpressionList(data) {
  return request({
    url: "module-listener-complex/planMisreData/list",
    method: "post",
    data,
  });
}
// 获取数据详情
function getErrorExpressionDetail(data) {
  return request({
    url: "module-listener-complex/planMisreData/getPlanMisreDataDetail/" + data,
    method: "get",
  });
}
// 数据收藏
function dataCollection(data) {
  return request({
    url: "module-listener-complex/planMisreData/collectPlanMisreData/" + data,
    method: "get",
  });
}
// 数据取消收藏
function dataNoCollection(data) {
  return request({
    url: "module-listener-complex/planMisreData/removeCollectPlanMisreData/" + data,
    method: "get",
  });
}
// 数据一键已读
function oneClickRead(data) {
  return request({
    url: "module-listener-complex/planMisreData/readPlanMisreData/" + data,
    method: "get",
  });
}
// 数据删除
function dataDelete(data) {
  return request({
    url: "module-listener-complex/planMisreData/deletePlanMisreData/" + data,
    method: "get",
  });
}
// 获取数据处置记录
function getDataDisposalRecord(data) {
  return request({
    url: "module-listener-complex/planMisreDisposalRecords/getRecords/" + data,
    method: "get",
  });
}
// 新增处置记录
function saveDisposalRecord(data) {
  return request({
    url: "module-listener-complex/planMisreDisposalRecords/saveRecords",
    method: "post",
    data,
  });
}
// 获取错误类型
function getErrorTypeList() {
  return request({
    url: "module-system/sysDictData/info/message_type",
    method: "get",
  });
}
// 获取平台类型
function getPlatformList() {
  return request({
    url: "module-system/sysDictData/info/platform_type",
    method: "get",
  });
}
// 获取主体类型
function getSubjectList() {
  return request({
    url: "module-system/sysDictData/info/body_type",
    method: "get",
  });
}
// 获取地区数据
function getAreaPathName(data) {
  return request({
    url: "module-web/sysRegionalConfig/getAreaPathName/" + data,
    method: "get",
  });
}
// 数据导出
function errorDataReport(data) {
  return request({
    url: "module-listener-complex/planMisreData/exportExcel",
    method: "post",
    data,
  });
}
// 获取错误类型(机构)
function getMisrepresentationSelect() {
  return request({
    url: "module-listener-complex/planMisreData/getOrgPlanMisres",
    method: "get",
  });
}
//获取错误表述列表
function getMisrepresentationList(data) {
  return request({
    url: '/module-system/planMisre/planMisreList',
    method: 'post',
    data
  })
}
// 提交通知函
function submitNoticeText(data) {
  return request({
    url: "module-listener-complex/planMisreDisposalRecords/saveNotification",
    method: "post",
    data,
  }); 
}
// 详情页人工预警
function warningPlanMisre(id) {
  return request({
    url:`module-listener-complex/planMisreData/warningPlanMisre/${id}`,
    method:'get'
  })
}
//获取不预警词列表
function getNoWarnList() {
  return request({
    url: `/module-listener-complex/planMisreData/getPlanMisreOrgWords`,
    method: 'get'
  })
}
//加入不预警词
function addNoWarnList(word) {
  return request({
    url: `/module-listener-complex/planMisreData/noWarningWordModification?word=`+word,
    method: 'get'
  })
}
//移除不预警词
function delNoWarnList(id) {
  return request({
    url: `/module-listener-complex/planMisreData/deleteNoWarningWordModification?id=`+id,
    method: 'get'
  })
}
//校验机构是否存在机构错误表述方案
function checkOrgPlanMisre() {
  return request({
    url: `/module-system/planMisre/checkOrgPlanMisre`,
    method: 'get'
  })
}
// 机构新增错误表述方案
export function orgSavePlanMisre(data) {
  return request({
    url: "/module-system/planMisre/orgSavePlanMisre",
    method: "post",
    data,
  });
}

function batchUpdateStatus(data) {
  return request({
    url: `/module-listener-complex/planMisreData/batchUpdateStatus`,
    method: 'post',
    data
  })
}
export default {
  checkOrgPlanMisre,
  orgSavePlanMisre,
  getErrorExpressionList,
  getSubjectList,
  getPlatformList,
  getErrorExpressionDetail,
  dataCollection,
  dataDelete,
  getDataDisposalRecord,
  getErrorTypeList,
  getAreaPathName,
  saveDisposalRecord,
  dataNoCollection,
  oneClickRead,
  errorDataReport,
  getMisrepresentationSelect,
  submitNoticeText,
  warningPlanMisre,
  getMisrepresentationList,
  getNoWarnList,
  addNoWarnList,
delNoWarnList,
batchUpdateStatus
};
