<template>
  <div class="title">
    <img src="@/assets/img/zhang/lingxing.png" alt="" />
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  vertical-align: middle;
  img {
    margin-top: 2px;
    vertical-align: middle;
    margin-right: 3px;
  }
}
</style>