<template>
  <div class="monitorbox-r">
    <div class="monitorbox-r-box">
      <div class="monitorbox-r-box-t">
        <!-- 平台类型 -->
        <div class="searchBox-box" style="padding: 0px">
          <div class="searchBox-box-L" style="position: relative; top: -20px">
            平台类型
          </div>
          <div class="searchBox-box-R m-l-24">
            <el-checkbox
              v-model="PlatformObj.checkAll"
              @change="CheckAllFn(1, $event)"
              style="margin-right: 30px; position: relative; top: -20px"
            >
              全选
            </el-checkbox>
            <el-checkbox-group
              class="m-l-24"
              v-model="PlatformObj.checkedform"
              @change="selectCheckAllFn(1, $event)"
            >
              <el-checkbox
                style="margin-bottom: 10px; margin-right: 50px"
                v-for="(item, index) in PlatformObj.checkedData"
                :label="item"
                :key="index"
              >
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <!-- 主体类型 -->
        <div class="searchBox-box">
          <div class="searchBox-box-L">主体类型</div>
          <div class="searchBox-box-R m-l-24">
            <el-checkbox
              style="margin-right: 40px"
              v-model="subjectObj.checkAll"
              @change="CheckAllFn(2, $event)"
            >
              全选
            </el-checkbox>
            <el-checkbox-group
              class="m-l-16"
              v-model="subjectObj.checkedform"
              @change="selectCheckAllFn(2, $event)"
            >
              <el-checkbox
                style="margin-right: 50px"
                v-for="(item, index) in subjectObj.checkedData"
                :label="item"
                :key="index"
              >
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <!-- 状态 -->
        <div class="searchBox-box">
          <div class="searchBox-box-L">状态</div>
          <div class="searchBox-box-R m-l-24">
            <!-- <el-checkbox
              class="m-l-24"
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              >全选</el-checkbox
            > -->
            <el-radio-group v-model="stateObj.checkedform">
              <el-radio
                style="margin-right: 55px"
                :label="item.value"
                v-for="item in stateObj.checkedData"
                :key="item.value"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
            <el-checkbox v-model="isWarn">已预警</el-checkbox>
            <!-- <el-checkbox-group
              class="m-l-24"
              v-model="stateObj.checkedform"
              @change="selectStateFn($event)"
            >
              <el-checkbox
                v-for="item in stateObj.checkedData"
                :label="item"
                :key="item.value"
                class="m-l-24"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group> -->
          </div>
        </div>
        <!-- 标签 or 时间 -->
        <div class="searchBox-box text searchBox-flex" style="border: none">
          <div class="searasdaschBox-flex-l">
            <el-select style="width: 120px" v-model="myinfo.reportVlaue" placeholder="文章标题">
              <el-option
                v-for="item in reportTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              class="m-l-16"
              style="width: 240px"
              v-model="myinfo.secrchValue"
              placeholder="请输入内容"
              clearable
            ></el-input>
            <span class="timefont m-l-32">时间</span>
            <el-date-picker
              style="height: 36px"
              class="m-l-16"
              v-model="myinfo.timeArr"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
          <div class="searchBox-flex-r">
            <div class="btn" @click="rightOk">查询</div>
            <div class="btn bai m-l-16" @click="resetFn">重置</div>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 16px; background: #f4f5fa"></div>
      <!-- 表格 -->
      <div class="monitorbox-r-box-c m-t-16">
        <!-- 表格内容 -->
        <div class="tableBody">
          <el-skeleton :rows="18" animated :loading="ifSkeleton" />
          <div class="tablebox" v-for="(item, index) in dataList" :key="index">
            <div class="tablebox-t">
              <div class="tablebox-t-l">
                <div class="biaoji">
                  {{ item.platformName }}
                </div>
                <div class="yujin m-l-16" v-if="item.warningOrgIds">已预警</div>
                <div class="desc m-l-16" @click="goDetail(item)" v-html="item.title"></div>
              </div>
            </div>
            <div class="tablebox-c" v-html="item.content"></div>
            <div class="tablebox-b">
              <div class="tablebox-b-t">
                <div class="tablebox-b-t-l">
                  <div>信息来源：{{ item.source || "无" }}</div>
                  <div class="m-l-40">所在地区：四川-广安-邻水县</div>
                  <div class="m-l-40">发布时间：{{ item.releaseTime }}</div>
                </div>
              </div>
              <div class="tablebox-b-b m-t-big">
                <template v-if="planType == 1">
                  <div v-if="item.collectOrgIds" @click="cancelCollect(item)">
                    <img src="@/assets/img/zhang/yishoucang.png" alt="" />
                    <span style="color: #ff9000; margin-left: 10px">已收藏</span>
                  </div>
                  <div @click="collect(item)" v-else>
                    <img src="@/assets/img/gjh/collection.png" alt="" class="m-r-8" />
                    收藏
                  </div>
                </template>

                <div class="m-l-40" @click="$main.copyFun(item.joiningTogether)">
                  <img src="@/assets/img/gjh/fz.png" alt="" class="m-r-8" />
                  复制全部
                </div>
                <div class="m-l-40" @click="$main.copyFun(item.webSiteAndTitle)">
                  <img src="@/assets/img/gjh/copy-link.png" alt="" class="m-r-8" />
                  复制链接和标题
                </div>
              </div>
            </div>
          </div>
          <Bitmap v-if="!ifSkeleton && dataList.length == 0" />
          <!-- 分页 -->
          <Pagination
            :pageNumber="page.page"
            :pageSize="page.limit"
            :total="total"
            @CurrentChange="CurrentChange"
            @SizeChange="SizeChange"
          ></Pagination>
        </div>
      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="deleteData"></deleteDialog>
  </div>
</template>

<script>
import deleteDialog from "@/components/deleteDialog.vue";
import Pagination from "@/components/pagination.vue";
import Bitmap from "@/components/bitmap.vue";
import { planMonitorDataList, collect, deleteData, cancelCollect, read } from "@/api/monitor/index";
export default {
  components: {
    Pagination,
    deleteDialog,
    Bitmap,
  },
  data() {
    return {
      planType: 1,
      isGjhList: [],
      isGjhIndeterminate: false, // 半选
      tablecheckAllGjh: false, // 全选
      isCollect: false, // 已收藏
      isWarn: false, // 已预警
      PlatformObj: {
        checkAll: false, // 平台类型全选
        checkedform: [], //平台类型已选
        checkedData: JSON.parse(window.localStorage.getItem("platform_type")), //平台类型 可选数据
        isIndeterminate: false, //平台类型不确定状态
      },
      // 主体类型
      subjectObj: {
        checkAll: false, // 主体类型全选
        checkedform: [], //主体类型已选
        checkedData: JSON.parse(window.localStorage.getItem("body_type")), //主体类型
        isIndeterminate: false, //主体类型不确定状态
      },
      // 状态
      stateObj: {
        checkedform: [], //主体类型已选
        checkedData: [
          { value: 1, label: "已读" },
          { value: 0, label: "未读" },
          { value: 2, label: "24小时未读" },
        ], //主体类型
      },
      myData: {}, // 头顶要展示的数据
      options: [
        { value: 0, label: "不预警" },
        { value: 1, label: "关键词预警" },
      ],
      plansType: 1, // 检测方案（1机构方案，2公共方案）
      monitorData: [], // 方案列表
      isIndeterminate: true,
      checkAll: false,
      checkedCities: ["上海", "北京"],
      cities: [],
      ifSkeleton: false, // 加载中
      myinfo: {
        // 配置 or 输入
        reportVlaue: 1, //选择类型
        timeArr: [],
        secrchValue: "", // 输入内容
      },
      // 搜索栏里 类型选择框
      reportTypes: [
        {
          //
          value: 1,
          label: "文章标题",
        },
        {
          value: 2,
          label: "文章内容",
        },
        {
          value: 3,
          label: "信息来源",
        },
      ],
      timeArr: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)], // 时间
      tablecheckAll: false,
      checkedCities1: [],
      input: "",
      value: "",
      total: 0,
      //  分页
      page: {
        limit: 10,
        page: 1,
      },
      dataList: [], // 数据列表
    };
  },
  methods: {
    // 去详情页
    goDetail(item) {
      const { id } = item;
      const platformTypeName = this.getType(item.platformType);
      let routeUrl = this.$router.resolve({
        path: "/settings/monitorDetail",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id,
              platformTypeName,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 列表全选
    handleCheckAllChange(val) {
      if (val) {
        this.dataList.forEach((v) => {
          v.isGjh = true;
        });
      } else {
        this.dataList.forEach((v) => {
          v.isGjh = false;
        });
      }
      this.isGjhList = this.dataList.filter((v) => v.isGjh);
      this.isGjhIndeterminate = false;
    },
    // 列表多选
    handleCheckedCitiesChange() {
      const { dataList } = this;
      const isGjhs = dataList.filter((v) => v.isGjh);
      this.isGjhList = isGjhs;
      this.tablecheckAllGjh = isGjhs.length === dataList.length; // 全选为true
      this.isGjhIndeterminate = isGjhs.length > 0 && isGjhs.length < dataList.length; // isIndeterminateOne：半选
    },
    // 获取平台类型
    getType(type) {
      let newType = null;
      this.PlatformObj.checkedData.forEach((v) => {
        if (type == Number(v.value)) {
          newType = v.label;
        }
      });
      return newType;
    },
    // 启用 || 停用
    async updateFn({ id }, status) {
      this.$emit("setUpdateFn", { id, status });
    },
    // 编辑
    selectPlanMonitor(obj) {
      this.$emit("selectPlanMonitor", obj);
    },
    // 查询
    rightOk() {
      this.total = 0;
      this.page = {
        limit: 10,
        page: 1,
      };
      this.getList();
    },
    // 重置
    resetFn() {
      const { myinfo, page, PlatformObj, subjectObj, isCollect, isWarn, stateObj } = this;
      // 批量处理重置
      this.$main.setReset(
        {
          myinfo,
          page,
          PlatformObj,
          subjectObj,
          isCollect,
          isWarn,
          stateObj,
        },
        this
      );
      this.getList();
    },
    // 获取列表
    async getList() {
      this.dataList = [];
      this.ifSkeleton = true;
      const {
        myinfo: {
          reportVlaue, //选择类型
          secrchValue, // 输入内容
          timeArr,
        },
        page,
        PlatformObj,
        subjectObj,
        stateObj,
        isWarn,
      } = this;
      this.isGjhList = [];
      this.isGjhIndeterminate = false;
      this.tablecheckAllGjh = false;
      const obj = {
        startTime: timeArr[0],
        endTime: timeArr[1],
        isCollect: 1,
        ...page,
      };
      if (isWarn) obj.isWarn = 1;
      if (PlatformObj.checkedform.length > 0)
        obj.platformType = PlatformObj.checkedform.map((item) => item.value);
      if (subjectObj.checkedform.length > 0)
        obj.bodyType = subjectObj.checkedform.map((item) => item.value);
      if (stateObj.checkedform > 0) obj.isRead = stateObj.checkedform;
      if (reportVlaue == 1) obj.title = secrchValue;
      if (reportVlaue == 2) obj.content = secrchValue;
      if (reportVlaue == 3) obj.source = secrchValue;
      const res = await planMonitorDataList(obj);
      if (res.data.code == 200) {
        this.ifSkeleton = false;
        this.dataList = res.data.data.list;
        this.total = Number(res.data.data.totalCount);
      } else {
        this.ifSkeleton = false;
        this.dataList = [];
      }
    },
    getData(data, planType) {
      this.myData = data;
      this.planType = planType;
    },
    // 收藏
    async collect(item) {
      const Res = await collect(item.id);
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("已收藏！");
        item.collectOrgIds = "有点东西"; // 别删  后端规定的 有点东西的后端
      }
    },
    // 取消收藏
    async cancelCollect(item) {
      const Res = await cancelCollect(item.id);
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("取消收藏成功！");
        item.collectOrgIds = null;
      }
    },
    deleteFn(item, type) {
      if (type == 7) {
        if (item.length == 0) {
          this.$message("请先选择一条要删除的数据");
          return;
        }
      }
      this.$refs.openDialog.openDialog(item, type);
    },
    // 删除
    async deleteData({ item, type }) {
      let dataDto = {};
      // 批量
      if (type == 7) {
        const { relationId } = item[0];
        const ids = item.map((v) => v.id);
        dataDto = {
          ids,
          relationId,
        };
        const {
          data: { code },
        } = await deleteData(dataDto);
        if (code == 200) {
          this.$message.success("已删除！");
          await this.$main.mySetTimeOut(1000);
          this.rightOk();
        }
      }
      // 单个
      if (type == 2) {
        const { id, relationId } = item;
        dataDto = {
          ids: [id],
          relationId,
        };
        const {
          data: { code },
        } = await deleteData(dataDto);
        if (code == 200) {
          this.$message.success("已删除！");
          await this.$main.mySetTimeOut(1000);
          this.rightOk();
        }
      }
      if (type == 8) {
        this.$emit("deletePlan", { item });
      }
    },
    // 全选按钮
    CheckAllFn(idx, val) {
      this.$main.selectAll(
        val,
        ...{
          1: [this.PlatformObj], // 平台类型
          2: [this.subjectObj], // 主体类型
        }[idx]
      );
    },
    // 多选按钮
    selectCheckAllFn(idx, { length }) {
      this.$main.selections(
        length,
        ...{
          1: [this.PlatformObj], // 平台类型
          2: [this.subjectObj], // 主体类型
        }[idx]
      );
    },
    CurrentChange(val) {
      this.page.page = val;
      this.getList();
    },
    SizeChange(val) {
      this.page.limit = val;
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.monitorbox-r {
  .monitorbox-r-box {
    padding: 16px 0px 24px 0px;
    border-radius: 8px;
    background: #ffffff;
    width: 100%;
    .monitorbox-r-box-t {
      padding: 0px 35px;
      .searchBox-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #e4e6ec;
        .timefont {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
        }
        .searchBox-box-L {
          text-align: right;
          min-width: 60px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #999999;
          margin-right: 25px;
        }
        .searchBox-box-R {
          display: flex;
          align-items: center;
        }
      }
      .searchBox-flex {
        display: flex;
        justify-content: space-between;
        .searchBox-flex-r {
          display: flex;
          justify-content: flex-start;
          .bai {
            background: #ffffff;
            color: black;
            border: 1px solid #e1e1e1;
          }
        }
      }
    }
    .monitorbox-r-box-c {
      .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333333;
        padding-bottom: 16px;
        .table-header-L {
          display: flex;
          justify-content: flex-start;

          span {
            color: #f58030;
          }
        }
        .table-header-R {
          display: flex;
          flex-direction: row;
          div {
            padding: 8px 16px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;
          }
          .d {
            background: #fff1e8;
            color: #ed731f;
          }
          .s {
            color: #ea3342;
            background: #fff2f3;
          }
          .y {
            background: #ffffff;
            color: black;
            border: 1px solid #e1e1e1;
          }
        }
      }
      .tableBody {
        padding: 0px 35px;
        .tablebox {
          border-bottom: 1px solid #e4e6ec;
          padding: 0 0 16px 0;
          .tablebox-t {
            display: flex;
            justify-content: space-between;
            padding: 16px 0;
            .tablebox-t-l {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
              color: #333333;
              width: 90%;
              .biaoji {
                font-size: 14px;
                color: #ffffff;
                font-weight: 500;
                padding: 4px 8px;
                background: #f58030;
                flex-shrink: 0;
                min-width: 40px;
              }
              .yujin {
                background: #ffedef;
                font-size: 14px;
                color: #ea3342;
                font-weight: 500;
                padding: 4px 8px;
              }
              .desc {
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 70%;
              }
            }
            .tablebox-t-r {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              span {
                margin-left: 6px;
              }
            }
            .red {
              color: #ea3342;
            }
          }
          .tablebox-c {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            max-height: 60px;
            overflow: hidden;
          }
          .tablebox-b {
            padding: 16px 0 0 0;
            // display: flex;
            // justify-content: space-between;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .tablebox-b-t {
              color: #666666;
              display: flex;
              justify-content: space-between;
              .tablebox-b-t-l {
                display: flex;
                justify-content: flex-start;
              }
            }
            .tablebox-b-b {
              display: flex;
              justify-content: flex-end;
              div {
                display: flex;
                align-items: center;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
.content {
  padding: 16px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 16px;

  .content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content-top-left {
      max-width: 290px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }

    .content-top-right {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .content-content {
    display: flex;
    align-items: center;
    margin-top: 24px;
    img {
      margin-right: 2px;
    }
    .content-content-delete {
      font-size: 14px;
      color: #ea3342;
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
    }
    .content-content-edit {
      font-size: 14px;
      color: #2e59ec;
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
    }
    .content-content-function {
      font-size: 14px;
      color: #02bc7c;
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
    }
    .content-content-stop {
      font-size: 14px;
      color: #ff9a16;
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
    }
  }
  .content-bottom {
    width: 100%;
    background: #f6f7fa;
    border-radius: 4px;
    padding: 16px;
    margin-top: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
  }
  .content-bottom:hover {
    box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.14);
  }
  .contentshad2 {
    box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.08);
  }
}
.content:hover {
  box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.08);
}
.dxpt {
  display: flex;
  justify-content: flex-start;
  .dxpt-l {
    flex: 1;
  }
}
.zxptList {
  padding: 16px;
  background: #f5f6fa;
  display: flex;
  justify-content: flex-start;
  .red {
    display: flex;
    align-items: center;
    color: #ea3342;
    cursor: pointer;
  }
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .flex-l {
    flex: 1;
  }
  .flex-r {
    flex: 1;
  }
  .yellow {
    display: flex;
    align-items: center;
    color: #f58030;
    cursor: pointer;
  }
  .red {
    display: flex;
    align-items: center;
    color: #ea3342;
    cursor: pointer;
    width: 100px;
  }
}
</style>
